import { CheckOutlined } from '@ant-design/icons';
import {
  Select as AntdSelect,
  Switch as AntdSwitch,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Space,
  Typography,
  theme
} from 'antd';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import DefaultCollectionImg from '../../../../../assets/images/collection.png';
import {
  MAX_LENGTHS,
  STATUS_OPTIONS,
  THEMES,
  THEME_PICKER_COLORS
} from '../../../../../common/constants';
import { formValidatorRules, getImageUrl } from '../../../../../common/utils';
import Image from '../../../../../components/Image';
import SelectableModal from '../../../../../components/SelectableModal';
import { GET_COLLECTIONS } from '../../../../labels/collections/graphql/Queries';
import { Select } from '../../../../videos/components/FormInputs';
import Preview from '../../../../videos/components/Preview';
import { GET_PERMISSIONS } from '../../../graphql/Queries';

export const ModuleFields = ({
  title: { InputComponent = Input, ...restTitleProps } = {}
}) => {
  return (
    <>
      <Form.Item
        label="Module Title"
        name="title"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)]}
      >
        <InputComponent placeholder="Enter title" {...restTitleProps} />
      </Form.Item>
      <Form.Item
        label="Module Description"
        name="description"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
      >
        <Input.TextArea rows={4} placeholder="Enter description" />
      </Form.Item>
      <Form.Item label="Module Status" name="status">
        <AntdSelect
          options={STATUS_OPTIONS.map(({ name, value }) => ({
            label: name,
            value
          }))}
          placeholder="Select status"
        />
      </Form.Item>
    </>
  );
};

export const Permissions = ({
  label = 'Permissions',
  excludeOptions = [],
  ...rest
}) => (
  <Form.Item name="permissions" label={label}>
    <Select
      mode="multiple"
      placeholder="Select permissions"
      query={GET_PERMISSIONS}
      excludeOptions={excludeOptions}
      dataSelector={(data) =>
        data?.permissions?.permissions?.map(({ key, name }) => ({
          label: name,
          value: key
        })) ?? []
      }
      queryOptions={{
        fetchPolicy: 'cache-first'
      }}
      notFoundContent="No Permissions!"
      {...rest}
    />
  </Form.Item>
);

export const Switch = ({ id, label, ...rest }) => {
  return (
    <Space align="center">
      <AntdSwitch id={id} {...rest} />
      <div className="ant-form-item-label pointer" style={{ padding: 0 }}>
        <label htmlFor={id}>{label}</label>
      </div>
    </Space>
  );
};

export const ShowFields = ({ settings, type }) => {
  const filteredSettings = useMemo(
    () => settings.filter(({ allowedTypes }) => allowedTypes.includes(type)),
    [type, settings]
  );

  if (!filteredSettings.length) return null;

  return (
    <Space direction="vertical" className="w-full">
      <Typography.Text>Show Fields</Typography.Text>
      <div>
        {filteredSettings.map(({ name, label }) => (
          <Form.Item
            className="m-0"
            key={name}
            name={['settings', name]}
            valuePropName="checked"
          >
            <Switch label={label} />
          </Form.Item>
        ))}
      </div>
    </Space>
  );
};

export const SelectCollections = ({
  multiple = true,
  onChange,
  value,
  id: fieldId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value.find((items) => items.id === item.id);
      if (isPresent) {
        onChange(value.filter((items) => items.id !== item.id));
      } else {
        onChange([
          ...value,
          {
            id: item.id,
            url: item.thumbnail?.url ?? '',
            title: item.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item.id,
      url: item.thumbnail?.url ?? '',
      title: item.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value.filter((items) => items.id !== item.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Collections' : 'Select Collection'}
        open={isOpen}
        onClose={closeModal}
        query={GET_COLLECTIONS}
        variablesSelector={(offset, limit, search) => ({
          filter: { skip: offset, limit, search }
        })}
        dataSelector={(data) => data.collectionsAdmin?.collections ?? []}
        keys={{
          data: 'collectionsAdmin',
          records: 'collections',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value.map((val) => val.id).includes(item.id)
            : value?.id === item?.id;
          return (
            <Card
              className="selectable-modal-card cs-card"
              cover={
                <>
                  {isSelected && (
                    <span className="checked-icon">
                      <CheckOutlined />
                    </span>
                  )}
                  <Image
                    className="label-poster"
                    src={
                      getImageUrl(item.thumbnail?.url, {
                        height: 200,
                        width: 200
                      }) ?? DefaultCollectionImg
                    }
                    alt=""
                  />
                </>
              }
              onClick={() => handleCardSelect(item)}
            >
              <Card.Meta
                title={<span className="label-title">{item.title}</span>}
              />
            </Card>
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value.length > 0 &&
              value.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item.id}
                  bgImg={
                    getImageUrl(item.url, { height: 200, width: 200 }) ||
                    DefaultCollectionImg
                  }
                >
                  <Preview.Title color={colorText}>{item.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value.id}
                bgImg={
                  getImageUrl(value.url, { height: 200, width: 200 }) ||
                  DefaultCollectionImg
                }
              >
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button id={fieldId} htmlType="button" onClick={openModal}>
          {value.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Collections' : 'Collection'}
        </Button>
      </Space>
    </>
  );
};

export const ThemePicker = ({ themes = [], onChange, value }) => {
  return (
    <Space className="flex-wrap">
      {themes?.map((themeType) => {
        const { backgroundColor, textLight } =
          THEME_PICKER_COLORS[themeType] ?? THEME_PICKER_COLORS[THEMES.BLUE];
        return (
          <div
            key={themeType}
            className={`theme-picker-item ${backgroundColor} ${
              value === themeType ? 'active-theme' : ''
            }`}
            onClick={() => onChange(themeType)}
          >
            <p className={`title ${textLight ? 'light' : ''}`}>Aa</p>
            <p className={`sub-title ${textLight ? 'light' : ''}`}>Aa</p>
            <div className="footer">Aa</div>
          </div>
        );
      })}
    </Space>
  );
};

export const DateTimePicker = ({ value, onChange, ...rest }) => {
  return (
    <DatePicker
      showTime={{ format: 'hh:mm A' }}
      style={{
        width: '100%'
      }}
      disabledDate={(day) => dayjs().subtract(1, 'day').isAfter(day)}
      value={value ? dayjs(value) : null}
      onChange={(_, date) => onChange(date ? dayjs(date).format() : '')}
      {...rest}
    />
  );
};

export const KeyInput = ({ onChange, ...rest }) => (
  <Input
    {...rest}
    onChange={(e) => {
      const { value } = e.target;
      onChange(value.replace(/[^a-zA-Z0-9_]/gi, '_').toLocaleLowerCase());
    }}
  />
);
