import { gql } from '@apollo/client';

export const CREATE_VIDEO_PLATFORM = gql`
  mutation createVideoPlatform($data: CreateVideoPlatformInput!) {
    createVideoPlatform(data: $data) {
      message
    }
  }
`;

export const UPDATE_VIDEO_PLATFORM = gql`
  mutation updateVideoPlatform(
    $data: UpdateVideoPlatformInput!
    $where: VideoPlatformWhereUniqueInput!
  ) {
    updateVideoPlatform(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_VIDEO_PLATFORM = gql`
  mutation deleteVideoPlatform($where: VideoPlatformWhereUniqueInput!) {
    deleteVideoPlatform(where: $where) {
      message
    }
  }
`;

export const IMPORT_PLATFORM_VIDEO_TO_ASSET = gql`
  mutation importPlatformVideoToAsset(
    $where: PlatformVideoUniqueWhereInput!
    $data: ImportPlatformVideoToAssetData!
  ) {
    importPlatformVideoToAsset(where: $where, data: $data) {
      message
    }
  }
`;
