import { useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as urlSlug from 'url-slug';
import { MAX_LENGTHS } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import { SlugInput } from '../../../../labels/topics/components/FormInputs';
import { useEditPage } from '../../../context/EditPageProvider';
import { UPDATE_PAGE } from '../../../graphql/Mutations';
import { Permissions } from './FormInputs';

const Settings = () => {
  const {
    setSelectionType,
    settings,
    fetchingPageDetails,
    updateSettings
  } = useEditPage();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...settings,
      permissions: settings?.permissions?.map((value) => ({
        label: value,
        value
      }))
    });
  }, [settings, form]);

  const { id } = useParams();

  const [updatePage, { loading }] = useMutation(UPDATE_PAGE);

  const handleSubmit = ({ slug, ...values }) => {
    const payload = {
      ...values,
      permissions: values?.permissions?.map(({ value }) => value),
      ...(settings?.allowDelete && {
        slug: slug.substring(1)
      })
    };
    updatePage({
      variables: {
        data: payload,
        where: { id }
      }
    })
      .then(
        ({
          data: {
            updatePage: { page: newSettings }
          }
        }) => {
          setSelectionType('');
          updateSettings(newSettings);
        }
      )
      .catch();
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const handleCancel = () => {
    setSelectionType('');
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={settings}
      disabled={fetchingPageDetails}
    >
      <Form.Item
        label="Title"
        name="title"
        required
        rules={[
          formValidatorRules?.required('Please enter title!'),
          formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
        ]}
      >
        <Input placeholder="Enter title" onChange={handleTitleChange} />
      </Form.Item>
      <Form.Item
        label="Slug"
        name="slug"
        rules={[
          {
            required: true,
            message: 'Please enter slug!'
          },
          formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
        ]}
      >
        <SlugInput
          readOnly={!settings?.allowDelete}
          disabled={!settings?.allowDelete}
        />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
      >
        <Input.TextArea placeholder="Enter description" />
      </Form.Item>

      <Form.Item
        name="metaHeader"
        label="Meta Header"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
      >
        <Input.TextArea rows={5} placeholder="Enter meta header" />
      </Form.Item>

      <Form.Item
        name="metaFooter"
        label="Meta Footer"
        rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
      >
        <Input.TextArea rows={5} placeholder="Enter meta footer" />
      </Form.Item>
      <Permissions />
      <div className="d-flex button-section">
        <Space>
          <Form.Item>
            <Button
              disabled={fetchingPageDetails || loading}
              loading={loading}
              htmlType="submit"
              className="text-btn mr-8"
              type="text"
            >
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="text"
              disabled={fetchingPageDetails || loading}
              className="text-btn2"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

export default Settings;
