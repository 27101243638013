import { CloseOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';

const Preview = ({ bgImg, children, onRemove }) => {
  return (
    <div className="preview-card">
      <img alt="" src={bgImg} />
      {onRemove && (
        <button type="button" className="remove-icon" onClick={onRemove}>
          <CloseOutlined />
        </button>
      )}
      {children}
    </div>
  );
};

Preview.Title = ({ bgColor = 'bg-n-500', color, children }) => {
  return (
    <Typography.Text
      className={`${bgColor}`}
      style={{
        color
      }}
    >
      {children}
    </Typography.Text>
  );
};

export default Preview;
