import { Badge, Button, ConfigProvider, Typography } from 'antd';
import { CaretRight, PlayCircle } from 'phosphor-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import FVC from '../../../assets/images/fv.png';
import Image from '../../../components/Image';
import WebStyle from '../../../styles/style-web.json';
import TagGrid from '../tagModules/TagGrid';

const { Paragraph } = Typography;

function FeaturedPodcastCard({
  tagName,
  heading,
  subHeading,
  description,
  tagData,
  src,
  date,
  blurHash,
  href = '#',
  tagBackground = 'bg-blue-2',
  tagTextColor = 'text-n-900',
  className,
  responsive,
  showCTA
}) {
  const { push } = useHistory();
  const handlePlay = () => {
    push(href);
  };
  return (
    <ConfigProvider theme={WebStyle}>
      <div
        className={`featuredvideocard ${className} ${
          responsive ? 'responsive-view' : ''
        }`}
      >
        <div className="fvc-image">
          <Image blurHash={blurHash} src={src || FVC} alt={heading} />
        </div>
        <div className="fvc-details">
          <div className="fvc-description">
            {tagName && (
              <Badge
                className={`badge-md bg-red-2 text-n-100  ${tagBackground} ${tagTextColor}`}
              >
                {tagName}
              </Badge>
            )}
            {heading && <div className="heading">{heading}</div>}
            {subHeading && (
              <div className="sub-heading text-n-0 mb-8">{subHeading}</div>
            )}
            {date && <div className="date">{date}</div>}
            {description && (
              <Paragraph className="description text-n-0">
                {description}
              </Paragraph>
            )}

            <TagGrid
              className="text-md tag-xs bg-tags b-0 text-n-0 mr-4"
              data={tagData}
              icon={<CaretRight className="ml-2" size={16} />}
            />
          </div>
          {showCTA && (
            <div className="slider-button">
              <Button
                className="button-l text-n-900 b-0 cs-button bg-n-100 gap-4 hbg-n-900 hc-n-100"
                size="large"
                block
                onClick={handlePlay}
              >
                <PlayCircle size={24} />
                Play
              </Button>
            </div>
          )}
        </div>
      </div>
    </ConfigProvider>
  );
}
export default FeaturedPodcastCard;
