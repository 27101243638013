import { useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Space, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_SORT_BY_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  RATIOS,
  STATUS_TYPES
} from '../../../../../common/constants';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import {
  ModuleFields,
  Permissions,
  SelectCollections,
  ShowFields,
  Switch
} from './FormInputs';

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  isDefaultModule: false,
  permissions: [],
  settings: {
    description: true,
    viewAll: true,
    collectionNumberOfVideos: true,
    separator: true,
    collectionDescription: true,
    allText: true,
    searchBar: true,
    collectionTitle: true,
    collectionImage: true
  },
  config: {
    autoGenerate: false,
    aspectRatio: RATIOS.SMALL,
    autoGenerateSortBy: null,
    collections:
      type === MODULE_TYPES.FEATURED_COLLECTION
        ? { id: '', url: '', title: '' }
        : []
  }
});

const RATIOS_OPTIONS = [
  {
    label: 'Small',
    value: RATIOS.SMALL
  },
  {
    label: 'Medium',
    value: RATIOS.MEDIUM
  },
  {
    label: 'Large',
    value: RATIOS.LARGE
  }
];

const SETTINGS = [
  {
    name: 'allText',
    label: 'All Text (Title & Description)',
    allowedTypes: [MODULE_TYPES.COLLECTION_GRID]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.COLLECTION_GRID,
      MODULE_TYPES.COLLECTION_LIST,
      MODULE_TYPES.COLLECTION_CAROUSEL
    ]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [
      MODULE_TYPES.COLLECTION_GRID,
      MODULE_TYPES.COLLECTION_LIST,
      MODULE_TYPES.COLLECTION_CAROUSEL
    ]
  },
  {
    name: 'collectionTitle',
    label: 'Collection Title',
    allowedTypes: [MODULE_TYPES.FEATURED_COLLECTION_SLIDER]
  },
  {
    name: 'collectionDescription',
    label: 'Collection Description',
    allowedTypes: [
      MODULE_TYPES.FEATURED_COLLECTION_SLIDER,
      MODULE_TYPES.COLLECTION_LIST,
      MODULE_TYPES.FEATURED_COLLECTION
    ]
  },
  {
    name: 'collectionImage',
    label: 'Collection Image',
    allowedTypes: [MODULE_TYPES.FEATURED_COLLECTION_SLIDER]
  },
  {
    name: 'searchBar',
    label: 'Search Bar',
    allowedTypes: [MODULE_TYPES.COLLECTION_GRID]
  },
  {
    name: 'collectionNumberOfVideos',
    label: 'Number Of Video',
    allowedTypes: [
      MODULE_TYPES.COLLECTION_GRID,
      MODULE_TYPES.COLLECTION_LIST,
      MODULE_TYPES.COLLECTION_CAROUSEL
    ]
  },
  {
    name: 'separator',
    label: 'Separator',
    allowedTypes: [MODULE_TYPES.COLLECTION_LIST]
  }
];

const AUTO_GENERATED_SORT_BY_OPTIONS = [
  { label: 'Recent', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM }
];

const MODULE_KEYS = {
  [MODULE_TYPES.COLLECTION_CAROUSEL]: 'collectionCarouselModule',
  [MODULE_TYPES.COLLECTION_LIST]: 'collectionListModule',
  [MODULE_TYPES.COLLECTION_GRID]: 'collectionGridModule',
  [MODULE_TYPES.FEATURED_COLLECTION]: 'featuredCollectionModule',
  [MODULE_TYPES.FEATURED_COLLECTION_SLIDER]: 'featuredCollectionSliderModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.COLLECTION_CAROUSEL]: 'Collection Carousel Configs',
  [MODULE_TYPES.COLLECTION_LIST]: 'Collection List Configs',
  [MODULE_TYPES.COLLECTION_GRID]: 'Collection Grid Configs',
  [MODULE_TYPES.FEATURED_COLLECTION]: 'Feature Collection Configs',
  [MODULE_TYPES.FEATURED_COLLECTION_SLIDER]: 'Feature Collection Slider Configs'
};

const { COLLECTION } = PAGE_TYPES;
const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.FEATURED_COLLECTION]: [COLLECTION]
};

const CollectionForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange,
  isDefaultPage,
  pageType
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  const isMultiCollection = type !== MODULE_TYPES.FEATURED_COLLECTION;
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);

  const configProps = Form.useWatch(['config'], form);
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);
  const { autoGenerate } = configProps ?? initialValues.config;

  const settingsProps = Form.useWatch(['settings'], form);
  useEffect(() => {
    if (settingsProps) {
      onSettingsChange(settingsProps);
    }
  }, [settingsProps]);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          autoGenerate:
            defaultValues?.moduleData?.config?.autoGenerate ?? false,
          aspectRatio:
            RATIOS[defaultValues?.moduleData?.config?.aspectRatio] ??
            RATIOS?.SMALL,
          autoGenerateSortBy:
            defaultValues?.moduleData?.config?.collectionAutoGenerateSortBy ||
            null,
          collections: isMultiCollection
            ? defaultValues?.moduleData?.config?.collections?.map(
                ({ id, title, thumbnail }) => ({
                  id,
                  title,
                  url: thumbnail?.url ?? ''
                })
              ) || []
            : {
                id: defaultValues?.moduleData?.config?.collection?.id ?? '',
                title:
                  defaultValues?.moduleData?.config?.collection?.title ?? '',
                url:
                  defaultValues?.moduleData?.config?.collection?.thumbnail
                    ?.url ?? ''
              }
        }
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    isMultiCollection
  ]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    // eslint-disable-next-line no-shadow
    isDefaultModule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;
    const isDefault = allowedTemplateConfig && isDefaultModule;

    // eslint-disable-next-line no-shadow
    const { collections, autoGenerate, ...restConfig } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          autoGenerate,
          ...restConfig,
          ...(isDefault
            ? { collectionId: '*' }
            : {
                ...(!autoGenerate &&
                  isMultiCollection && {
                    collections:
                      collections.map(({ id }, i) => ({
                        collectionId: id,
                        order: i + 1
                      })) ?? []
                  }),
                ...(!autoGenerate &&
                  !isMultiCollection && {
                    collectionId: collections?.id ?? ''
                  })
              })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          {allowedTemplateConfig && pageType === COLLECTION && (
            <>
              <Typography.Text>Template Config</Typography.Text>
              <Form.Item
                className="m-0"
                name="isDefaultModule"
                valuePropName="checked"
              >
                <Switch label="Use Template Data" />
              </Form.Item>
            </>
          )}
          {(!allowedTemplateConfig ||
            (allowedTemplateConfig && !isDefaultModule)) && (
            <>
              <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
              <div>
                <Form.Item
                  name={['config', 'autoGenerate']}
                  valuePropName="checked"
                >
                  <Switch
                    label={
                      isMultiCollection
                        ? 'Auto Generated Collections'
                        : 'Auto Generated Collection'
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Aspect Ratio"
                  name={['config', 'aspectRatio']}
                >
                  <AntdSelect
                    options={RATIOS_OPTIONS}
                    placeholder="Please select aspect ratio"
                  />
                </Form.Item>
                {autoGenerate ? (
                  <>
                    <Form.Item
                      label="Auto Generated Sort By"
                      name={['config', 'autoGenerateSortBy']}
                    >
                      <AntdSelect
                        options={AUTO_GENERATED_SORT_BY_OPTIONS}
                        placeholder="Select sort by"
                      />
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item
                    label={
                      isMultiCollection
                        ? 'Select Collections'
                        : 'Select Collection'
                    }
                    name={['config', 'collections']}
                  >
                    <SelectCollections multiple={isMultiCollection} />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default CollectionForm;
