import { gql } from '@apollo/client';

export const GET_VIDEO_PLATFORMS = gql`
  query videoPlatforms($filter: VideoPlatformFilter, $sort: VideoPlatformSort) {
    videoPlatforms(filter: $filter, sort: $sort) {
      videoPlatforms {
        id
        provider {
          name
        }
        name
        isDefault
        createdBy {
          id
          email
        }
        createdAt
      }
      count
    }
  }
`;

export const GET_VIDEO_PLATFORM = gql`
  query videoPlatform($where: VideoPlatformWhereUniqueInput!) {
    videoPlatform(where: $where) {
      id
      configs
      provider {
        name
        key
        configs
      }
      name
      createdBy {
        id
        email
      }
      createdAt
    }
  }
`;

export const GET_VIDEO_BY_PLATFORM = gql`
  query getVideosByPlatform(
    $where: VideoPlatformWhereUniqueInput!
    $filter: VideosByPlatformFilter
    $sort: VideosByPlatformSort
  ) {
    getVideosByPlatform(where: $where, filter: $filter, sort: $sort) {
      platformVideos {
        id
        title
        description
        imageThumbnailUrl
        videoThumbnailUrl
      }
      count
    }
  }
`;

export const GET_VIDEO_URL_BY_PLATFORM_AND_VIDEOID = gql`
  query getVideoUrlByPlatformAndVideoId(
    $where: PlatformVideoUniqueWhereInput!
  ) {
    getVideoUrlByPlatformAndVideoId(where: $where) {
      id
      title
      url
    }
  }
`;

export const GET_VIDEO_PROVIDERS = gql`
  query videoProvidersAdmin($filter: VideoProvidersFilter) {
    videoProvidersAdmin(filter: $filter) {
      count
      videoProviders {
        name
        key
        configs
      }
    }
  }
`;
