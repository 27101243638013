import { gql } from '@apollo/client';

export const GET_ASSETS = gql`
  query assets($filter: AssetsFilter, $sort: AssetsSort) {
    assets(filter: $filter, sort: $sort) {
      count
      assets {
        id
        title
        blurhash
        status
        description
        languageCode
        categoryKey
        serviceVideoThumbnail
        serviceImageThumbnail
        url
        createdAt
      }
    }
  }
`;

export const GET_ASSET = gql`
  query asset($where: AssetWhereUniqueInput!) {
    asset(where: $where) {
      id
      title
      description
      languageCode
      categoryKey
      serviceImageThumbnail
      url
      createdAt
    }
  }
`;

export const GET_UPLOAD_SIGNED_URL = gql`
  query getAssetUploadSignedUrl($data: GetAssetUploadSignedUrlInput!) {
    getAssetUploadSignedUrl(data: $data) {
      signedUrl
      key
    }
  }
`;

export const GET_VIDEO_UPLOAD_SIGNED_URL = gql`
  query getVideoUploadSignedUrl($data: GetVideoUploadSignedUrlInput) {
    getVideoUploadSignedUrl(data: $data) {
      signedUrl
      uploadId
    }
  }
`;

export const GET_LANGUAGES = gql`
  query languages($filter: LanguageFilter, $sort: LanguageSort) {
    languages(filter: $filter, sort: $sort) {
      languages {
        id
        name
        code
      }
      count
    }
  }
`;
