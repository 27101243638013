import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as urlSlug from 'url-slug';
import {
  ASSET_CATEGORY,
  COLOUR_CODES,
  MAX_LENGTHS,
  MODULES,
  PAGE_TYPES,
  ROUTES
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CreatePageModal, {
  useCreatePage
} from '../../../components/CreatePageModal';
import PageHeader from '../../../components/PageHeader';
import history from '../../../historyData';
import { Switch } from '../../pages/component/pageModules/moduleForms/FormInputs';
import { SelectAsset } from '../../videos/components/FormInputs';
import { CREATE_AUTHOR, UPDATE_AUTHOR } from './graphql/Mutations';
import { GET_AUTHOR } from './graphql/Queries';

const initialValues = {
  isActive: true,
  firstName: '',
  lastName: '',
  slug: '',
  description: '',
  subTitle: '',
  image: null,
  thumbnail: null,
  metaHeader: '',
  metaFooter: ''
};

const AddEditAuthor = (props) => {
  const [form] = Form.useForm();
  const firstName = Form.useWatch('firstName', form) ?? '';
  const lastName = Form.useWatch('lastName', form) ?? '';
  const { location } = props;
  const { id: authorId } = useParams();
  const { isUpdate } = location?.state || {};
  const {
    page,
    TYPES,
    setPage,
    getModalProps,
    openModal,
    navigateToEditPage
  } = useCreatePage(PAGE_TYPES.AUTHOR);

  const [colorCode, setColorCode] = useState(null);
  const [colorIndex, setColorIndex] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [fetchAuthor, { loading: fetchingDetails }] = useLazyQuery(GET_AUTHOR, {
    variables: { where: { id: authorId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      form.setFieldsValue({
        ...res?.authorAdmin,
        slug: res?.authorAdmin?.slug?.startsWith('/')
          ? res?.authorAdmin?.slug
          : `/${res?.authorAdmin?.slug}`
      });

      setColorCode(res?.authorAdmin?.primaryColor);
      setColorIndex(
        COLOUR_CODES?.findIndex(
          (col) => col?.color === res?.authorAdmin?.primaryColor
        )
      );

      setPage({
        ...res?.authorAdmin?.page,
        slug: res?.authorAdmin?.slug ?? ''
      });
    }
  });

  useEffect(() => {
    if (authorId) {
      fetchAuthor();
    }
  }, [authorId]);

  const [createAuthorMutate] = useMutation(CREATE_AUTHOR, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAuthorMutate] = useMutation(UPDATE_AUTHOR, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    history?.replace(ROUTES?.AUTHOR);
  };

  const colorHandler = (color, index) => {
    setColorCode(color);
    setColorIndex(index);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    try {
      const formData = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        description: values?.description,
        subTitle: values?.subTitle,
        slug: values?.slug?.startsWith('/')
          ? values?.slug?.substring(1)
          : values?.slug,
        imageId: values?.image?.id ? values?.image?.id : null,
        thumbnailId: values?.thumbnail?.id ? values?.thumbnail?.id : null,
        primaryColor: colorCode,
        ...(authorId && {
          isActive: !!values?.isActive
        }),
        metaHeader: values?.metaHeader || '',
        metaFooter: values?.metaFooter || ''
      };

      if (isUpdate) {
        const response = await updateAuthorMutate({
          variables: {
            where: { id: authorId },
            data: {
              ...formData
            }
          }
        });
        if (response?.data?.updateAuthor) {
          setButtonLoading(false);
          history?.replace(ROUTES?.AUTHOR);
        }
      } else {
        const response = await createAuthorMutate({
          variables: {
            data: {
              ...formData
            }
          }
        });
        if (response?.data?.createAuthor) {
          setButtonLoading(false);
          history?.replace(ROUTES?.AUTHOR);
        }
      }
    } catch (error) {
      setButtonLoading(false);
      return error;
    }
  };

  const handleFirstNameChange = (e) => {
    const value = e?.target?.value;
    const separator = value && lastName ? '-' : '';
    form?.setFieldValue(
      'slug',
      `/${urlSlug?.convert(value)}${separator}${urlSlug?.convert(lastName)}`
    );
  };
  const handleLastNameChange = (e) => {
    const value = e?.target?.value;
    const separator = value && firstName ? '-' : '';
    form?.setFieldValue(
      'slug',
      `/${urlSlug?.convert(firstName)}${separator}${urlSlug?.convert(value)}`
    );
  };

  return (
    <>
      <CreatePageModal {...getModalProps({ title: 'Create Speaker Page' })} />
      <PageHeader menu={MODULES?.LABELS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            initialValues={initialValues}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="firstName"
              label="Firstname"
              required
              rules={[
                formValidatorRules?.required('Please enter firstname!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input
                placeholder="Enter firstname"
                disabled={buttonLoading}
                onChange={handleFirstNameChange}
              />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Lastname"
              required
              rules={[
                formValidatorRules?.required('Please enter lastname!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input
                placeholder="Enter lastname"
                onChange={handleLastNameChange}
                disabled={buttonLoading}
              />
            </Form.Item>

            <Form.Item
              name="slug"
              label="Slug"
              rules={[
                { required: true, message: 'Please enter page slug!' },
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input placeholder="Enter Page Slug" disabled={buttonLoading} />
            </Form.Item>
            {authorId && (
              <Form.Item name="isActive" valuePropName="checked">
                <Switch label="Active" />
              </Form.Item>
            )}
            <Form.Item
              name="subTitle"
              label="Subtitle"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)]}
            >
              <Input placeholder="Enter subtitle" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>
            <Form.Item label="Color">
              <div className="d-flex">
                {COLOUR_CODES?.map((item, index) => (
                  <div
                    key={item?.color}
                    className={
                      colorIndex === index
                        ? `active-color color-list ${item?.color}`
                        : `color-list ${item?.color}`
                    }
                    onClick={() => colorHandler(item?.color, index)}
                  />
                ))}
              </div>
            </Form.Item>

            <Form.Item label="Main Image" name="image">
              <SelectAsset
                modalTitle="Select Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>

            <Form.Item label="Thumbnail Image" name="thumbnail">
              <SelectAsset
                modalTitle="Select Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>

            <Form.Item
              name="metaHeader"
              label="Meta Header"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item
              name="metaFooter"
              label="Meta Footer"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>

            <div className="d-flex button-section mb-8">
              <Space>
                <Button
                  loading={buttonLoading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>

                <Button
                  type="text"
                  className="text-btn2"
                  disabled={buttonLoading}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
            {isUpdate && page.type === TYPES.CREATE && (
              <Button
                disabled={fetchingDetails}
                htmlType="button"
                type="text"
                className="text-btn"
                onClick={openModal}
              >
                Create Custom Page
              </Button>
            )}
            {isUpdate && page.type === TYPES.UPDATE && (
              <Button
                disabled={fetchingDetails}
                htmlType="button"
                type="text"
                className="text-btn"
                onClick={navigateToEditPage}
              >
                Update Custom Page
              </Button>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditAuthor;
