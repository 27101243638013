import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Modal, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { PencilSimpleLine, Trash } from 'phosphor-react';
import React, { useRef } from 'react';
import AudioPlaceholder from '../../../assets/images/audioPlaceholder.jpg';
import {
  ASSET_CATEGORY,
  MODULES,
  ROUTES,
  STATUS_COLORS
} from '../../../common/constants';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import history from '../../../historyData';
import CustomCard from '../../component/card/Cscard';
import { DELETE_ASSET } from '../graphql/Mutations';
import { GET_ASSETS } from '../graphql/Queries';

const { confirm } = Modal;

const status = {
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  ERRORED: 'Errored',
  READY: 'Ready'
};

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    limit,
    skip: offset,
    search,
    categoryKey: ASSET_CATEGORY?.AUDIO
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ assets }) => ({
  data: assets?.assets || [],
  count: assets?.count || 0
});

function Audio() {
  const ref = useRef();
  const filterProps = useFilterBar();
  const [deleteAudio] = useMutation(DELETE_ASSET, {
    onError() {}
  });

  const handleEdit = (id) => {
    history.push(`${ROUTES?.AUDIO}/${id}/edit`, {
      isUpdate: true,
      audioId: id
    });
  };

  const handleShowModal = () => {
    history?.push(`${ROUTES?.AUDIO}/add`, { isUpdate: false });
  };

  const handlePreview = (item) => {
    if (ref.current.showPreview) {
      ref.current.showPreview({
        title: item.title,
        url: item.url,
        type: ASSET_CATEGORY.AUDIO,
        showPlaceHolder: !item?.serviceImageThumbnail
      });
    }
  };

  const handleDelete = async (id) => {
    confirm({
      title: 'Are you sure, you want to delete this audio?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      async onOk() {
        deleteAudio({
          variables: { where: { id } }
        })
          .then(({ errors }) => {
            if (ref.current.removeItem && !errors) {
              ref.current.removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'url',
      key: 'url',
      editable: true,
      width: '12%',
      render: (text, record) => (
        <img
          onClick={() => handlePreview(record)}
          className="group-img-table pointer"
          src={
            record?.serviceVideoThumbnail ??
            record?.serviceImageThumbnail ??
            AudioPlaceholder
          }
          alt={`${record?.title}`}
        />
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '22%'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '22%'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '22%',
      render: (value) => (
        <Tag className="m-0 custom-tag" color={STATUS_COLORS?.[value]}>
          {status?.[value]}
        </Tag>
      )
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: '12%',
      render: (text, record) => (
        <div>
          <Tooltip title="Edit Audio">
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(record?.id);
              }}
            />
          </Tooltip>
          <span onClick={(e) => e.stopPropagation()}>
            <Tooltip title="Delete Audio">
              <DeleteOutlined onClick={() => handleDelete(record?.id)} />
            </Tooltip>
          </span>
        </div>
      )
    }
  ];

  return (
    <>
      <PageHeader menu={MODULES?.ASSETS} handleShowModal={handleShowModal} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Audio' }}
      />
      <PageList
        ref={ref}
        filters={filterProps.filters}
        listMode={filterProps.listMode}
        query={GET_ASSETS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        GridProps={{
          renderItem: (item) => (
            <CustomCard
              onClick={() => {
                handlePreview(item);
              }}
              status={status?.[item.status]}
              statusColor={STATUS_COLORS?.[item.status]}
              alt={item?.title}
              src={
                item?.serviceVideoThumbnail ??
                item?.serviceImageThumbnail ??
                AudioPlaceholder
              }
              title={moment(item?.createdAt).format('ll')}
              heading={item?.title}
              description={item?.description}
              primaryOnClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                handleEdit(item?.id);
              }}
              secondaryOnClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                handleDelete(item?.id);
              }}
              primary={
                <>
                  <PencilSimpleLine size={16} />
                  Edit
                </>
              }
              secondary={
                <>
                  <Trash size={16} />
                  Delete
                </>
              }
            />
          )
        }}
        TableProps={{ columns }}
      />
    </>
  );
}

export default Audio;
