import { useLazyQuery, useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  USER_ROLES
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import { Permissions } from '../pages/component/pageModules/moduleForms/FormInputs';
import { CREATE_USER, UPDATE_USER } from './graphql/Mutations';
import { GET_USER } from './graphql/Queries';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  roles: [],
  contactInfo: {
    number: '',
    code: ''
  },
  permissions: []
};

const PhoneInput = ({ value, onChange, placeholder, id }) => {
  const { number, code } = value;

  const handleChange = (val, { dialCode }) => {
    onChange({
      number: val.replace(dialCode, ''),
      code: `+${dialCode}`
    });
  };

  return (
    <ReactPhoneInput
      inputProps={{ id }}
      placeholder={placeholder}
      value={`${code.replace('+', '')}${number}`}
      onChange={handleChange}
    />
  );
};

const excludePermissions = ['LOGGED_IN', 'NOT_LOGGED_IN'];

const AddEditUser = ({ history, match: { params } }) => {
  const [form] = Form.useForm();
  const { userId } = params;
  const isEdit = !!userId;

  const [getUser, { loading: fetchingDetails }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only'
  });

  const [addUpdateUser, { loading }] = useMutation(
    isEdit ? UPDATE_USER : CREATE_USER
  );

  useEffect(() => {
    if (isEdit && !!userId) {
      getUser({
        variables: {
          where: {
            id: userId
          }
        }
      }).then(({ data, error }) => {
        if (!error && !!data) {
          form.setFieldsValue({
            firstName: data?.user?.firstName ?? '',
            lastName: data?.user?.lastName ?? '',
            email: data?.user?.email ?? '',
            roles: data?.user?.roles ?? [],
            contactInfo: {
              number: data?.user?.contactNumber ?? '',
              code: data?.user?.countryCode ?? ''
            },
            permissions:
              data?.user?.permissions?.map((value) => ({
                label: value,
                value
              })) ?? []
          });
        }
      });
    }
  }, [isEdit, userId, form]);

  const handleSubmit = ({ contactInfo, permissions, email, ...restValues }) => {
    const payload = {
      ...restValues,
      ...(!isEdit && { email }),
      contactNumber: contactInfo.number,
      countryCode: contactInfo.code,
      ...(isEdit && {
        permissions: permissions?.map(({ value }) => value) ?? []
      })
    };

    addUpdateUser({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: userId
          }
        })
      }
    }).then(() => {
      history.push(ROUTES?.USERS);
    });
  };

  const handleCancel = () => {
    history.push(ROUTES?.USERS);
  };

  return (
    <>
      <PageHeader menu={MODULES?.USERS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={fetchingDetails}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              required
              rules={[
                formValidatorRules?.required('Please enter first name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.NAME)]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter email!'
                },
                formValidatorRules.email,
                formValidatorRules?.maxLength(MAX_LENGTHS.FORM_INPUT)
              ]}
            >
              <Input
                disabled={isEdit}
                readOnly={isEdit}
                placeholder="Enter email"
              />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="contactInfo"
              rules={[
                formValidatorRules?.maxLength(
                  MAX_LENGTHS.PHONE_NUMBER,
                  undefined,
                  (value) => value?.number?.length > MAX_LENGTHS.PHONE_NUMBER
                )
              ]}
            >
              <PhoneInput placeholder="Enter phone number" />
            </Form.Item>
            <Form.Item
              label="Roles"
              name="roles"
              required
              rules={[
                {
                  async validator(_, value) {
                    if (!value?.length) {
                      throw new Error('Please select roles!');
                    }
                  }
                }
              ]}
            >
              <AntdSelect
                mode="multiple"
                options={USER_ROLES}
                placeholder="Select roles"
              />
            </Form.Item>
            {isEdit && (
              <Permissions
                label="Attributes"
                placeholder="Select attributes"
                excludeOptions={excludePermissions}
              />
            )}
            <div className="d-flex button-section mb-8">
              <Space>
                <Button
                  disabled={loading || fetchingDetails}
                  loading={loading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditUser;
