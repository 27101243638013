import { useLazyQuery, useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import * as urlSlug from 'url-slug';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  PAGE_TYPES,
  ROUTES,
  STATUS_OPTIONS,
  STATUS_TYPES,
  UNPUBLISHED_STATUS,
  VIDEO_RESOURCES_TYPES
} from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import { GET_TAGS, GET_TOPICS, GET_VIDEO } from './graphql/Queries';

import { formValidatorRules } from '../../common/utils';
import CreatePageModal, {
  useCreatePage
} from '../../components/CreatePageModal';
import { SlugInput } from '../labels/topics/components/FormInputs';
import { Permissions } from '../pages/component/pageModules/moduleForms/FormInputs';
import {
  Editor,
  initialAsset,
  Select,
  SelectAsset,
  SelectAuthor
} from './components/FormInputs';
import { CREATE_VIDEO, UPDATE_VIDEO } from './graphql/Mutations';

const initialResources = {
  [VIDEO_RESOURCES_TYPES.QUESTIONS]: '',
  [VIDEO_RESOURCES_TYPES.QUOTES]: '',
  [VIDEO_RESOURCES_TYPES.SCRIPTURE]: '',
  [VIDEO_RESOURCES_TYPES.LINKS]: ''
};
const initialValues = {
  title: '',
  description: '',
  slug: '/',
  tags: [],
  topicId: null,
  status: STATUS_TYPES.DRAFT,
  contentRating: '',
  authors: [],
  video: {
    ...initialAsset
  },
  image: {
    ...initialAsset
  },
  imageThumbnail: {
    ...initialAsset
  },
  videoThumbnail: {
    ...initialAsset
  },
  timelineThumbnail: {
    ...initialAsset
  },
  documents: [],
  audios: [],
  tracks: [],
  metaHeader: '',
  metaFooter: '',
  resources: {
    ...initialResources
  },
  permissions: []
};

const resources = [
  { label: 'Questions', name: VIDEO_RESOURCES_TYPES.QUESTIONS },
  { label: 'Quotes', name: VIDEO_RESOURCES_TYPES.QUOTES },
  { label: 'Scripture', name: VIDEO_RESOURCES_TYPES.SCRIPTURE },
  { label: 'Links', name: VIDEO_RESOURCES_TYPES.LINKS }
];

const AddEditVideo = ({ history, match: { params } }) => {
  const [form] = Form.useForm();
  const { videoId } = params;
  const isEdit = !!videoId;

  const {
    page,
    TYPES,
    setPage,
    getModalProps,
    openModal,
    navigateToEditPage
  } = useCreatePage(PAGE_TYPES.VIDEO);

  const [fetchVideoDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_VIDEO,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateVideo, { loading }] = useMutation(
    isEdit ? UPDATE_VIDEO : CREATE_VIDEO
  );

  useEffect(() => {
    if (isEdit && !!videoId) {
      fetchVideoDetails({
        variables: {
          id: videoId
        }
      }).then((res) => {
        const video = res.data?.videoAdmin;
        if (video) {
          const slug = video?.slug.startsWith('/')
            ? video?.slug
            : `/${video?.slug}`;
          form.setFieldsValue({
            title: video?.title ?? '',
            description: video?.description ?? '',
            slug,
            permissions:
              video?.permissions?.map((value) => ({
                label: value,
                value
              })) ?? [],
            tags:
              video?.tags?.map((tag) => ({
                label: tag?.name,
                value: tag?.key
              })) ?? [],
            topicId: video?.topics?.length
              ? {
                  label: video?.topics?.[0]?.name,
                  value: video?.topics?.[0]?.id
                }
              : null,
            status: video?.status ?? STATUS_TYPES.DRAFT,
            contentRating: video?.contentRating ?? '',
            authors:
              video?.authors?.map((author) => ({
                id: author.id ?? '',
                firstName: author.firstName ?? '',
                lastName: author.lastName ?? '',
                imageURL: author.image?.url ?? '',
                color: author.primaryColor ?? ''
              })) ?? [],
            video: {
              id: video?.video?.id ?? '',
              url:
                video?.video?.serviceVideoThumbnail ??
                video?.video?.serviceImageThumbnail ??
                ''
            },
            image: {
              id: video?.image?.id ?? '',
              url: video?.image?.url ?? ''
            },
            imageThumbnail: {
              id: video?.imageThumbnail?.id ?? '',
              url: video?.imageThumbnail?.url ?? ''
            },
            videoThumbnail: {
              id: video?.videoThumbnail?.id ?? '',
              url: video?.videoThumbnail?.url ?? ''
            },
            timelineThumbnail: {
              id: video?.timelineThumbnail?.id ?? '',
              url: video?.timelineThumbnail?.url ?? ''
            },
            documents:
              video?.documents?.map(({ id, url, title }) => ({
                id,
                url,
                title
              })) ?? [],
            audios:
              video?.audioTracks?.map(({ id, url, title }) => ({
                id,
                url,
                title
              })) ?? [],
            tracks:
              video?.textTracks?.map(({ id, url, title }) => ({
                id,
                url,
                title
              })) ?? [],
            metaHeader: video?.metaHeader ?? '',
            metaFooter: video?.metaFooter ?? '',
            resources: (video?.resources ?? [])?.reduce(
              (acc, data) => {
                if (data?.content) {
                  acc[VIDEO_RESOURCES_TYPES[data?.type]] = data?.content;
                }
                return acc;
              },
              { ...initialResources }
            )
          });
          setPage({ ...video.page, slug: video.slug });
        }
      });
    }
  }, [isEdit, videoId, form, fetchVideoDetails, setPage]);

  const handleCancel = () => {
    history.replace(ROUTES.VIDEOS_MODULE);
  };

  const handleSubmit = (data) => {
    const payload = {
      title: data?.title || '',
      description: data?.description || '',
      slug: data?.slug.startsWith('/') ? data?.slug.substring(1) : data?.slug,
      authorIds: data?.authors?.map((author) => author?.id) ?? [],
      tags: data?.tags?.map(({ value }) => value) || null,
      topicIds: data?.topicId?.value ? [data?.topicId?.value] : [],
      status: data?.status,
      contentRating: data?.contentRating || '',
      imageId: data?.image?.id || null,
      imageThumbnailId: data?.imageThumbnail?.id || null,
      videoThumbnailId: data?.videoThumbnail?.id || null,
      timelineThumbnailId: data?.timelineThumbnail?.id || null,
      videoId: data?.video?.id || null,
      audioTrackIds: data?.audios?.map(({ id }) => id),
      textTrackIds: data?.tracks?.map(({ id }) => id),
      documentIds: data?.documents?.map(({ id }) => id),
      metaHeader: data?.metaHeader || '',
      metaFooter: data?.metaFooter || '',
      resources: Object.entries(VIDEO_RESOURCES_TYPES).map(([key, name]) => ({
        type: key,
        content: data?.resources?.[name] ?? ''
      })),
      permissions: data?.permissions?.map(({ value }) => value)
    };

    addUpdateVideo({
      variables: {
        data: payload,
        ...(isEdit && {
          id: videoId
        })
      }
    }).then(() => {
      history.push(ROUTES?.VIDEOS_MODULE);
    });
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  return (
    <>
      <CreatePageModal {...getModalProps({ title: 'Create Video Page' })} />
      <PageHeader menu={MODULES?.VIDEOS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={fetchingDetails}
          >
            <Form.Item
              label="Title"
              name="title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input placeholder="Enter title" onChange={handleTitleChange} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please enter slug!'
                },
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <SlugInput />
            </Form.Item>
            <Form.Item
              label="Speakers"
              name="authors"
              required
              rules={[
                {
                  async validator(_, value) {
                    if (value?.length < 1) {
                      throw new Error('Please select at least one speaker!');
                    }
                  }
                }
              ]}
            >
              <SelectAuthor multiple />
            </Form.Item>
            <Form.Item name="tags" label="Tags">
              <Select
                mode="multiple"
                placeholder="Select tags"
                query={GET_TAGS}
                variablesSelector={(filter) => ({ filter })}
                dataSelector={(data) =>
                  data?.tagsAdmin?.tags?.map(({ key, name }) => ({
                    label: name,
                    value: key
                  })) ?? []
                }
                keys={{
                  data: 'tagsAdmin',
                  records: 'tags',
                  count: 'count'
                }}
              />
            </Form.Item>
            <Form.Item
              name="topicId"
              label="Topic"
              required
              rules={[
                {
                  async validator(_, value) {
                    if (!value) {
                      throw new Error('Please select topic!');
                    }
                  }
                }
              ]}
            >
              <Select
                placeholder="Select topic"
                query={GET_TOPICS}
                variablesSelector={(filter) => ({ filter })}
                dataSelector={(data) =>
                  data?.topicsAdmin?.topics?.map(({ id, name }) => ({
                    label: name,
                    value: id
                  })) ?? 0
                }
                keys={{
                  data: 'topicsAdmin',
                  records: 'topics',
                  count: 'count'
                }}
              />
            </Form.Item>
            <Form.Item label="Status" name="status">
              <AntdSelect
                options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                  ({ name, value }) => ({
                    label: name,
                    value
                  })
                )}
                placeholder="Select status"
              />
            </Form.Item>
            <Form.Item
              name="contentRating"
              label="Content Rating"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea placeholder="Enter content rating" />
            </Form.Item>

            <Form.Item
              label="Video"
              name="video"
              required
              rules={[
                {
                  async validator(_, value) {
                    if (value.id === '') {
                      throw new Error('Please select video!');
                    }
                  }
                }
              ]}
            >
              <SelectAsset
                modalTitle="Select Video"
                categoryKey={ASSET_CATEGORY.VIDEO}
                btnText="Video"
                dataSelector={({
                  id,
                  serviceImageThumbnail,
                  serviceVideoThumbnail
                }) => ({
                  id,
                  url: serviceVideoThumbnail || serviceImageThumbnail
                })}
              />
            </Form.Item>
            <Form.Item
              label="Image"
              name="image"
              extra="Recommended size  (2500 * 1242)"
            >
              <SelectAsset
                modalTitle="Select Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>
            <Form.Item
              label="Image Thumbnail"
              name="imageThumbnail"
              extra="Recommended size  (590 * 330)"
            >
              <SelectAsset
                modalTitle="Select Image Thumbnail"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image Thumbnail"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>
            <Form.Item
              label="Video Thumbnail"
              name="videoThumbnail"
              extra="Recommended size  (590 * 330)"
            >
              <SelectAsset
                modalTitle="Select Video Thumbnail"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Video Thumbnail"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>
            {/* <Form.Item label="Timeline Thumbnail" name="timelineThumbnail">
              <SelectAsset
                modalTitle="Select Timeline Thumbnail"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Timeline Thumbnail"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item> */}
            <Form.Item label="Text Tracks" name="tracks">
              <SelectAsset
                multiple
                modalTitle="Select Tracks"
                btnText="Tracks"
                categoryKey={ASSET_CATEGORY.TEXT}
                dataSelector={({ id, url, title }) => ({
                  id,
                  url,
                  title
                })}
              />
            </Form.Item>
            <Form.Item label="Audios" name="audios">
              <SelectAsset
                multiple
                modalTitle="Select Audios"
                btnText="Audios"
                categoryKey={ASSET_CATEGORY.AUDIO}
                dataSelector={({ id, url, title }) => ({
                  id,
                  url,
                  title
                })}
              />
            </Form.Item>
            <Form.Item label="Documents" name="documents">
              <SelectAsset
                multiple
                modalTitle="Select Documents"
                btnText="Documents"
                categoryKey={ASSET_CATEGORY.DOCUMENT}
                dataSelector={({ id, url, title }) => ({
                  id,
                  url,
                  title
                })}
              />
            </Form.Item>

            {resources.map(({ label, name }) => (
              <Form.Item
                key={name}
                name={['resources', name]}
                label={label}
                rules={[
                  formValidatorRules?.maxLength(
                    4000,
                    'Max length of 4000 character exceeded'
                  )
                ]}
              >
                <Editor />
              </Form.Item>
            ))}

            <Form.Item
              name="metaHeader"
              label="Meta Header"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item
              name="metaFooter"
              label="Meta Footer"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>

            <Permissions />

            <div className="d-flex button-section mb-8">
              <Space>
                <Button
                  disabled={loading || fetchingDetails}
                  loading={loading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
            {isEdit && page.type === TYPES.CREATE && (
              <Button
                disabled={fetchingDetails}
                htmlType="button"
                type="text"
                className="text-btn"
                onClick={openModal}
              >
                Create Custom Page
              </Button>
            )}
            {isEdit && page.type === TYPES.UPDATE && (
              <Button
                disabled={fetchingDetails}
                htmlType="button"
                type="text"
                className="text-btn"
                onClick={navigateToEditPage}
              >
                Update Custom Page
              </Button>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditVideo;
