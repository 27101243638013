import { useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Space, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_SORT_BY_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATUS_TYPES
} from '../../../../../common/constants';
import { SelectAuthor } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  isDefaultModule: false,
  permissions: [],
  settings: {
    description: true,
    viewAll: true,
    authorNumberOfVideos: true,
    separator: true,
    authorDescription: true,
    authorName: true,
    authorImage: true
  },
  config: {
    autoGenerate: false,
    autoGenerateSortBy: null,
    authors:
      type === MODULE_TYPES.FEATURED_AUTHOR
        ? {
            id: '',
            firstName: '',
            lastName: '',
            imageURL: '',
            color: ''
          }
        : []
  }
});

const SETTINGS = [
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.AUTHOR_LIST,
      MODULE_TYPES.AUTHOR_CAROUSEL,
      MODULE_TYPES.AUTHOR_GRID
    ]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [
      MODULE_TYPES.AUTHOR_LIST,
      MODULE_TYPES.AUTHOR_CAROUSEL,
      MODULE_TYPES.AUTHOR_GRID
    ]
  },
  {
    name: 'authorName',
    label: 'Speaker Name',
    allowedTypes: [MODULE_TYPES.FEATURED_AUTHOR]
  },
  {
    name: 'authorDescription',
    label: 'Speaker Description',
    allowedTypes: [MODULE_TYPES.AUTHOR_LIST]
  },
  {
    name: 'authorImage',
    label: 'Speaker Image',
    allowedTypes: [MODULE_TYPES.FEATURED_AUTHOR]
  },
  {
    name: 'authorNumberOfVideos',
    label: 'Number Of Video',
    allowedTypes: [
      MODULE_TYPES.FEATURED_AUTHOR,
      MODULE_TYPES.AUTHOR_LIST,
      MODULE_TYPES.AUTHOR_CAROUSEL,
      MODULE_TYPES.AUTHOR_GRID
    ]
  },
  {
    name: 'separator',
    label: 'Separator',
    allowedTypes: [MODULE_TYPES.AUTHOR_LIST]
  }
];

const AUTO_GENERATED_SORT_BY_OPTIONS = [
  { label: 'Recent', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM }
];

const MODULE_KEYS = {
  [MODULE_TYPES.AUTHOR_CAROUSEL]: 'authorCarouselModule',
  [MODULE_TYPES.AUTHOR_LIST]: 'authorListModule',
  [MODULE_TYPES.AUTHOR_GRID]: 'authorGridModule',
  [MODULE_TYPES.FEATURED_AUTHOR]: 'featuredAuthorModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.AUTHOR_CAROUSEL]: 'Speaker Carousel Configs',
  [MODULE_TYPES.AUTHOR_LIST]: 'Speaker List Configs',
  [MODULE_TYPES.AUTHOR_GRID]: 'Speaker GRid Configs',
  [MODULE_TYPES.FEATURED_AUTHOR]: 'Feature Speaker Configs'
};
const { AUTHOR } = PAGE_TYPES;
const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.FEATURED_AUTHOR]: [AUTHOR]
};

const AuthorForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange,
  isDefaultPage,
  pageType
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);

  const isMultiAuthor = type !== MODULE_TYPES.FEATURED_AUTHOR;
  const configProps = Form.useWatch(['config'], form);
  const { autoGenerate } = configProps ?? initialValues.config;
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);
  const settingsProps = Form.useWatch(['settings'], form);
  useEffect(() => {
    if (settingsProps) {
      onSettingsChange(settingsProps);
    }
  }, [settingsProps]);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          autoGenerate:
            defaultValues?.moduleData?.config?.autoGenerate ?? false,
          autoGenerateSortBy:
            defaultValues?.moduleData?.config?.authorAutoGenerateSortBy || null,
          authors: isMultiAuthor
            ? defaultValues?.moduleData?.config?.authors?.map(
                ({ id, firstName, lastName, image, primaryColor }) => ({
                  id,
                  firstName,
                  lastName,
                  color: primaryColor,
                  imageURL: image?.url ?? ''
                })
              ) || []
            : {
                id: defaultValues?.moduleData?.config?.author?.id ?? '',
                firstName:
                  defaultValues?.moduleData?.config?.author?.firstName ?? '',
                lastName:
                  defaultValues?.moduleData?.config?.author?.lastName ?? '',
                color:
                  defaultValues?.moduleData?.config?.author?.primaryColor ?? '',
                imageURL:
                  defaultValues?.moduleData?.config?.author?.image?.url ?? ''
              }
        }
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    isMultiAuthor
  ]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    // eslint-disable-next-line no-shadow
    isDefaultModule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;
    const isDefault = allowedTemplateConfig && isDefaultModule;

    // eslint-disable-next-line no-shadow
    const { authors, autoGenerate, ...restConfig } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          autoGenerate,
          ...restConfig,
          ...(isDefault
            ? {
                authorId: '*'
              }
            : {
                ...(!autoGenerate &&
                  isMultiAuthor && {
                    authors:
                      authors.map(({ id }, i) => ({
                        authorId: id,
                        order: i + 1
                      })) ?? []
                  }),
                ...(!autoGenerate &&
                  !isMultiAuthor && {
                    authorId: authors?.id ?? ''
                  })
              })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          {allowedTemplateConfig && pageType === AUTHOR && (
            <>
              <Typography.Text>Template Config</Typography.Text>
              <Form.Item
                className="m-0"
                name="isDefaultModule"
                valuePropName="checked"
              >
                <Switch label="Use Template Data" />
              </Form.Item>
            </>
          )}
          {(!allowedTemplateConfig ||
            (allowedTemplateConfig && !isDefaultModule)) && (
            <>
              <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
              <div>
                <Form.Item
                  name={['config', 'autoGenerate']}
                  valuePropName="checked"
                >
                  <Switch
                    label={
                      isMultiAuthor
                        ? 'Auto Generated Speakers'
                        : 'Auto Generated Speaker'
                    }
                  />
                </Form.Item>
                {autoGenerate ? (
                  <>
                    <Form.Item
                      label="Auto Generated Sort By"
                      name={['config', 'autoGenerateSortBy']}
                    >
                      <AntdSelect
                        options={AUTO_GENERATED_SORT_BY_OPTIONS}
                        placeholder="Select sort by"
                      />
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item
                    label={isMultiAuthor ? 'Select Speakers' : 'Select Speaker'}
                    name={['config', 'authors']}
                  >
                    <SelectAuthor multiple={isMultiAuthor} />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default AuthorForm;
