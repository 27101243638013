import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddEditVideoPlatform from './AddEditVideoPlatform';
import VideoPlatforms from './VideoPlatforms';

const VideoPlatformsWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.VIDEO_PLATFORMS} component={VideoPlatforms} />
      <Route
        exact
        path={`${ROUTES?.VIDEO_PLATFORMS}/add`}
        component={AddEditVideoPlatform}
      />
      <Route
        exact
        path={`${ROUTES?.VIDEO_PLATFORMS}/:platformId/edit`}
        component={AddEditVideoPlatform}
      />
      <Route
        exact
        path={`${ROUTES?.VIDEO_PLATFORMS}/:platformId`}
        render={() => <Redirect to={ROUTES?.VIDEO_PLATFORMS} />}
      />
    </Switch>
  );
};

export default VideoPlatformsWrapper;
