import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { ArrowClockwise, PencilSimpleLine } from 'phosphor-react';
import React, { useCallback, useMemo } from 'react';
import podcastPlaceholder from '../../assets/images/imagePlaceholder.png';
import {
  CLOUD_FRONT_CACHE_KEYS,
  MODULES,
  ROUTES
} from '../../common/constants';
import { getImageUrl } from '../../common/utils';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import Image from '../../components/Image';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import CustomCard from '../component/card/Cscard';
import { INVALIDATE_CLOUD_FRONT_CACHE } from '../menus/graphql/Mutations';
import { GET_PODCASTS_MODULE } from './graphql/Queries';

const PodcastCard = ({
  createdAt,
  id,
  title,
  description,
  imageThumbnail,
  onEdit,
  onPreview
}) => {
  return (
    <CustomCard
      onClick={onPreview}
      alt={title}
      src={getImageUrl(imageThumbnail?.url) ?? podcastPlaceholder}
      blurHash={imageThumbnail?.blurhash}
      title={moment(createdAt).format('ll')}
      heading={title}
      description={description}
      primaryOnClick={() => onEdit(id)}
      primary={
        <>
          <PencilSimpleLine size={16} />
          Edit
        </>
      }
    />
  );
};

const getColumns = (onEdit) => [
  {
    title: 'Video',
    dataIndex: 'imageThumbnail',
    ellipsis: true,
    render: (value, { title }) => (
      <Image
        className="group-img-table"
        blurHash={value?.blurhash}
        src={
          getImageUrl(value?.url, { height: 50, width: 80 }) ??
          podcastPlaceholder
        }
        alt={title}
      />
    )
  },
  {
    title: 'Title',
    dataIndex: 'title',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    ellipsis: true
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: (value) => (
      <Tooltip title="Edit Video">
        <Button type="text" className="text-btn" onClick={() => onEdit(value)}>
          <EditOutlined />
        </Button>
      </Tooltip>
    )
  }
];

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    skip: offset,
    search,
    limit
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ podcastsAdmin }) => ({
  data: podcastsAdmin?.podcasts ?? [],
  count: podcastsAdmin?.count ?? 0
});

const Podcast = ({ history }) => {
  const filterProps = useFilterBar();
  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.OG_IMAGES }
    }
  );

  const handleShowModal = () => {
    history.push(`${ROUTES?.PODCAST_MODULE}/add`);
  };

  const handleEdit = useCallback((id) => {
    history.push(`${ROUTES?.PODCAST_MODULE}/${id}/edit`);
  }, []);

  const columns = useMemo(() => getColumns(handleEdit), [handleEdit]);

  return (
    <div>
      <PageHeader menu={MODULES?.PODCAST} handleShowModal={handleShowModal} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Podcasts' }}
      >
        <Tooltip title="Revalidate OG images" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={invalidateCache}
            loading={isInvalidating}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        filters={filterProps.filters}
        listMode={filterProps.listMode}
        query={GET_PODCASTS_MODULE}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        GridProps={{
          renderItem: (props) => <PodcastCard {...props} onEdit={handleEdit} />
        }}
        TableProps={{ columns }}
      />
    </div>
  );
};

export default Podcast;
