import { Tabs, Typography } from 'antd';
import React from 'react';
import AuthorWrapper from './authorModules';
import CategoryWrapper from './categoryModule';
import CollectionWrapper from './collectionModule';
import CtaWrapper from './ctaModule';
import FeaturedWrapper from './featuredModules';
import LatyoutWrapper from './layout';
import MarketingComponent from './marketingComponent';
import SmallComponentWrapper from './smallComponent';
import TagWrapper from './tagModules';
import VideoWrapper from './videoModule';

function Component() {
  const { Title } = Typography;
  const onChange = (key) => {
    // eslint-disable-next-line no-console
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: `Category`,
      children: <CategoryWrapper />
    },
    {
      key: '2',
      label: `Video`,
      children: <VideoWrapper />
    },
    {
      key: '3',
      label: `Collection`,
      children: <CollectionWrapper />
    },
    {
      key: '4',
      label: `Author`,
      children: <AuthorWrapper />
    },
    {
      key: '5',
      label: `CTA`,
      children: <CtaWrapper />
    },
    {
      key: '6',
      label: 'Tag',
      children: <TagWrapper />
    },
    {
      key: '7',
      label: 'Featured',
      children: <FeaturedWrapper />
    },

    {
      key: '8',
      label: 'Layout',
      children: <LatyoutWrapper />
    },
    {
      key: '9',
      label: 'other',
      children: <SmallComponentWrapper />
    },
    {
      key: '10',
      label: 'marketing',
      children: <MarketingComponent />
    }
  ];
  return (
    <>
      <div className="page-header">
        <Title level={4}>Component</Title>
      </div>
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      </div>
    </>
  );
}
export default Component;
