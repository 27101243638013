import { useLazyQuery, useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import * as urlSlug from 'url-slug';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  PAGE_TYPES,
  ROUTES,
  STATUS_OPTIONS,
  STATUS_TYPES,
  UNPUBLISHED_STATUS
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CreatePageModal, {
  useCreatePage
} from '../../../components/CreatePageModal';
import PageHeader from '../../../components/PageHeader';
import { SelectAsset } from '../../videos/components/FormInputs';
import { SlugInput } from '../topics/components/FormInputs';
import { ColorPicker, SelectVideos } from './components/FormInputs';
import { CREATE_COLLECTION, UPDATE_COLLECTION } from './graphql/Mutations';
import { GET_COLLECTION } from './graphql/Queries';

const initialValues = {
  title: '',
  slug: '/',
  status: STATUS_TYPES.DRAFT,
  thumbnail: {
    id: '',
    url: ''
  },
  primaryColor: 'bg-n-800',
  videoData: [],
  metaHeader: '',
  metaFooter: ''
};

const AddEditCollection = ({ history, match: { params } }) => {
  const [form] = Form.useForm();
  const { collectionId } = params;
  const {
    page,
    TYPES,
    setPage,
    getModalProps,
    openModal,
    navigateToEditPage
  } = useCreatePage(PAGE_TYPES.COLLECTION);

  const isEdit = !!collectionId;

  const [fetchCollectionDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_COLLECTION,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateCollection, { loading }] = useMutation(
    isEdit ? UPDATE_COLLECTION : CREATE_COLLECTION
  );

  useEffect(() => {
    if (isEdit && !!collectionId) {
      fetchCollectionDetails({
        variables: {
          id: collectionId
        }
      })
        .then(({ data }) => {
          const collection = data?.collectionAdmin;
          const videos = data?.collectionVideos || [];
          if (collection) {
            const {
              title,
              slug,
              primaryColor,
              thumbnail,
              status,
              metaHeader,
              metaFooter
            } = collection;
            form.setFieldsValue({
              title,
              slug: slug.startsWith('/') ? slug : `/${slug}`,
              status,
              primaryColor,
              thumbnail: thumbnail ?? {
                id: '',
                url: ''
              },
              videoData: videos.map(
                ({ id, videoThumbnail, title: videoTitle }) => ({
                  id,
                  title: videoTitle,
                  url: videoThumbnail?.url || ''
                })
              ),
              metaHeader: metaHeader ?? '',
              metaFooter: metaFooter ?? ''
            });
            setPage({ ...collection.page, slug });
          }
        })
        .catch();
    }
  }, [collectionId, isEdit, form, setPage]);

  const handleSubmit = ({ slug, thumbnail, videoData, ...restValues }) => {
    const payload = {
      slug: slug.startsWith('/') ? slug.substring(1) : slug,
      thumbnailId: thumbnail?.id || null,
      videoData: videoData.map(({ id }, index) => ({
        videoId: id,
        order: index + 1
      })),
      ...restValues
    };

    addUpdateCollection({
      variables: {
        data: payload,
        ...(isEdit && {
          id: collectionId
        })
      }
    })
      .then(() => {
        history.push(ROUTES?.COLLECTIONS);
      })
      .catch();
  };

  const handleCancel = () => {
    history.push(ROUTES?.COLLECTIONS);
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  return (
    <>
      <CreatePageModal
        {...getModalProps({ title: 'Create Collection Page' })}
      />
      <PageHeader menu={MODULES?.LABELS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            className="add-edit-form"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
            disabled={fetchingDetails}
          >
            <Form.Item
              label="Title"
              name="title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input placeholder="Enter title" onChange={handleTitleChange} />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please enter slug!'
                },
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <SlugInput />
            </Form.Item>

            <Form.Item label="Status" name="status">
              <AntdSelect
                options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                  ({ name, value }) => ({
                    label: name,
                    value
                  })
                )}
                placeholder="Select status"
              />
            </Form.Item>

            <Form.Item label="Background Color" name="primaryColor">
              <ColorPicker />
            </Form.Item>
            <Form.Item label="Main Image" name="thumbnail">
              <SelectAsset
                modalTitle="Select Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>
            <Form.Item label="Videos" name="videoData">
              <SelectVideos />
            </Form.Item>
            <Form.Item
              name="metaHeader"
              label="Meta Header"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item
              name="metaFooter"
              label="Meta Footer"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>
            <div className="d-flex button-section mb-8">
              <Space>
                <Button
                  disabled={loading || fetchingDetails}
                  loading={loading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>
                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
            {isEdit && page.type === TYPES.CREATE && (
              <Button
                disabled={fetchingDetails}
                htmlType="button"
                type="text"
                className="text-btn"
                onClick={openModal}
              >
                Create Custom Page
              </Button>
            )}
            {isEdit && page.type === TYPES.UPDATE && (
              <Button
                disabled={fetchingDetails}
                htmlType="button"
                type="text"
                className="text-btn"
                onClick={navigateToEditPage}
              >
                Update Custom Page
              </Button>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditCollection;
