import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload } from 'antd';

import heic2any from 'heic2any';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  ROUTES
} from '../../../common/constants';
import {
  Blurhash,
  fileUpload,
  formValidatorRules,
  getBase64
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import PageHeader from '../../../components/PageHeader';
import PreviewModal from '../../../components/PreviewModal';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import { CREATE_ASSET, UPDATE_ASSET } from '../graphql/Mutations';
import { GET_ASSET, GET_UPLOAD_SIGNED_URL } from '../graphql/Queries';

const { TextArea } = Input;

const AddEditImage = (props) => {
  const { location } = props;
  const { imageId, isUpdate } = location?.state;
  const [imageData, setImageData] = useState({});
  const [imageLoading, setImageLoading] = useState(true);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [previewTitle, setPreviewTitle] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [imageProgress, setImageProgress] = useState(undefined);
  const [fetchImage] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: imageId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setImageLoading(true);
      setImageData(res?.asset);
      setImageLoading(false);
    },
    onError: () => {
      setImageLoading(false);
    }
  });

  useEffect(() => {
    if (imageId) {
      fetchImage();
    } else {
      setImageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageId]);

  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL, {
    fetchPolicy: 'network-only'
  });
  const [createAssetMutate] = useMutation(CREATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAssetMutate] = useMutation(UPDATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    history?.replace(ROUTES?.MAIN);
  };

  const handlePreview = async (file) => {
    let preview;
    if (!file?.url && !file?.preview) {
      preview = await getBase64(file?.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewTitle(
      file?.name ||
        file?.label ||
        file?.url?.substring(file?.url?.lastIndexOf('/') + 1)
    );
    setPreviewImage(file?.url || preview);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    const { image } = values;

    try {
      const textData = {
        title: values?.title,
        description: values?.description
      };
      let key = null;
      let blurhash = null;
      let contentType = null;

      if (image?.length > 0) {
        setImageProgress(0);
        let imagefile = image?.[0]?.originFileObj;
        let fileName = image?.[0]?.name;
        contentType = image?.[0]?.type;
        if (
          imagefile?.type?.toLowerCase() === 'image/heic' ||
          imagefile?.name?.toLowerCase()?.includes('.heic')
        ) {
          const ext = fileName
            ?.toLowerCase()
            ?.split('.')?.[1]
            .replace('heic', 'jpeg');
          const file = fileName?.split('.')?.[0];
          fileName = `${file}.${ext}`;
          imagefile = await heic2any({
            blob: imagefile,
            toType: 'image/jpeg',
            quality: 0.8
          });
        }
        const getSignedPutUrlResult = await getSignedUrl({
          variables: {
            data: {
              fileName: fileName?.replace(/ /g, '_'),
              contentType,
              assetType: ASSET_CATEGORY?.IMAGE
            }
          }
        });
        if (getSignedPutUrlResult?.data?.getAssetUploadSignedUrl) {
          await fileUpload(
            getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.signedUrl,
            imagefile,
            setImageProgress
          );
          key = getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key;
          blurhash = await Blurhash.encode(imagefile);
        }
      }
      const media = {
        ...textData,
        categoryKey: ASSET_CATEGORY?.IMAGE,
        contentType,
        key,
        blurhash
      };
      if (!key) {
        delete media?.key;
      }
      if (!blurhash) {
        delete media?.blurhash;
      }

      if (isUpdate) {
        const response = await updateAssetMutate({
          variables: {
            where: { id: imageId },
            data: {
              ...media
            }
          }
        });
        if (response?.data?.updateAsset) {
          setButtonLoading(false);
          history?.replace(ROUTES?.IMAGES);
        }
      } else {
        const response = await createAssetMutate({
          variables: {
            data: {
              ...media
            }
          }
        });
        if (response?.data?.createAsset) {
          history?.replace(ROUTES?.IMAGES);
          setImageProgress(undefined);
          setButtonLoading(false);
        }
      }
    } catch (error) {
      setImageProgress(undefined);
      setButtonLoading(false);
      return error;
    }
  };

  const initialValues = {
    ...imageData
  };

  if (imageLoading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <PreviewModal
        previewData={previewImage}
        previewTitle={previewTitle}
        previewVisible={previewVisible}
        setPreviewVisible={setPreviewVisible}
        previewType={ASSET_CATEGORY?.IMAGE}
      />
      <PageHeader menu={MODULES?.ASSETS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            initialValues={initialValues || { isActive: true }}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input placeholder="Enter title" disabled={buttonLoading} />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <TextArea
                rows={2}
                placeholder="Enter description"
                disabled={buttonLoading}
              />
            </Form.Item>

            <Form.Item
              name="image"
              label="Image"
              rules={[{ required: !isUpdate, message: 'Please select image!' }]}
              getValueFromEvent={(e) => {
                if (isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
              valuePropName="fileList"
            >
              <Upload
                maxCount={1}
                disabled={buttonLoading}
                onPreview={handlePreview}
                accept=".jpg,.jpeg,.png,.heic,.gif"
                beforeUpload={() => false}
              >
                <Space>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  <span>(.jpg, .jpeg, .png, .heic, .gif)</span>
                </Space>
              </Upload>
            </Form.Item>

            {imageProgress >= 0 && (
              <Form.Item>
                <ProgressBar progress={imageProgress} />
              </Form.Item>
            )}
            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={buttonLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={buttonLoading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditImage;
