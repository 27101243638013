import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Modal, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  LIST_TYPES,
  MODULES,
  ROUTES,
  VIDEO_PLATFORMS_SORT_FIELD
} from '../../common/constants';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import { Select } from '../videos/components/FormInputs';
import { DELETE_VIDEO_PLATFORM } from './graphql/Mutations';
import { GET_VIDEO_PLATFORMS, GET_VIDEO_PROVIDERS } from './graphql/Queries';

const { confirm } = Modal;

const dataSelector = (data) => ({
  data: data?.videoPlatforms?.videoPlatforms ?? [],
  count: data?.videoPlatforms?.count ?? 0
});

const variablesSelector = ({
  offset,
  sortField,
  sortOrder,
  search,
  providerKey,
  limit
}) => ({
  filter: {
    skip: offset,
    search,
    providerKey,
    limit
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const videoProviderVariableSelctor = ({ skip, limit }) => ({
  filter: {
    skip,
    limit
  }
});

const Action = ({ id, isDefault, removeItem }) => {
  const history = useHistory();
  const [deleteVideoPlatform] = useMutation(DELETE_VIDEO_PLATFORM);
  const handleEdit = () => {
    history.push(`${ROUTES?.VIDEO_PLATFORMS}/${id}/edit`);
  };

  const handleDelete = async () => {
    confirm({
      title: 'Are you sure, you want to delete this video platform?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      async onOk() {
        deleteVideoPlatform({
          variables: { where: { id } }
        })
          .then(({ errors }) => {
            if (!errors) {
              removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  return (
    <>
      <Tooltip title="Edit Video Platform">
        <Button type="text" className="text-btn" onClick={handleEdit}>
          <EditOutlined />
        </Button>
      </Tooltip>
      <span onClick={(e) => e.stopPropagation()}>
        <Tooltip title="Delete Video Platform">
          <Button
            disabled={isDefault}
            className="text-btn"
            type="text"
            onClick={handleDelete}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      </span>
    </>
  );
};

const columns = ({ removeItem }) => [
  {
    title: 'Name',
    dataIndex: 'name',
    ellipsis: true
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    ellipsis: true,
    render: (provider) => provider?.name
  },
  {
    title: 'Type',
    dataIndex: 'isDefault',
    ellipsis: true,
    render: (_, record) => (
      <span>
        <b>{record?.isDefault ? 'Default' : '-'}</b>
      </span>
    )
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: (_, record) => <Action {...record} removeItem={removeItem} />
  }
];

const VideoPlatforms = ({ history }) => {
  const [provider, setProvider] = useState(null);

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: VIDEO_PLATFORMS_SORT_FIELD,
      field: VIDEO_PLATFORMS_SORT_FIELD[0].value
    }
  });

  const handleShowModal = () => {
    history.push(`${ROUTES?.VIDEO_PLATFORMS}/add`);
  };

  const handleProviderChange = (value) => setProvider(value);

  const filters = useMemo(
    () => ({
      ...filterProps.filters,
      providerKey: provider ? provider?.value : null
    }),
    [filterProps.filters, provider]
  );

  return (
    <>
      <>
        <PageHeader
          menu={MODULES?.VIDEO_PLATFORMS}
          handleShowModal={handleShowModal}
        />
        <FilterBar
          {...filterProps}
          show={{
            listModes: false
          }}
          SearchProps={{ placeholder: 'Search Platforms' }}
        >
          <Select
            allowClear
            searchable={false}
            dropdownMatchSelectWidth={false}
            variablesSelector={videoProviderVariableSelctor}
            value={provider}
            onChange={handleProviderChange}
            placeholder="Select provider"
            query={GET_VIDEO_PROVIDERS}
            dataSelector={(data) =>
              data?.videoProvidersAdmin?.videoProviders?.map(
                ({ key, name }) => ({
                  label: name,
                  value: key
                })
              ) ?? []
            }
            keys={{
              data: 'videoProvidersAdmin',
              records: 'videoProviders',
              count: 'count'
            }}
          />
        </FilterBar>
        <PageList
          filters={filters}
          listMode={LIST_TYPES.TABLE}
          query={GET_VIDEO_PLATFORMS}
          variablesSelector={variablesSelector}
          dataSelector={dataSelector}
          TableProps={{ columns }}
          limit={20}
        />
      </>
    </>
  );
};

export default VideoPlatforms;
