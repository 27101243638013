import { useLazyQuery, useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import * as urlSlug from 'url-slug';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  PAGE_TYPES,
  ROUTES,
  STATUS_OPTIONS,
  STATUS_TYPES,
  UNPUBLISHED_STATUS
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import CreatePageModal, {
  useCreatePage
} from '../../components/CreatePageModal';
import PageHeader from '../../components/PageHeader';
import { SlugInput } from '../labels/topics/components/FormInputs';
import { Permissions } from '../pages/component/pageModules/moduleForms/FormInputs';
import {
  Select,
  SelectAsset,
  SelectAuthor,
  initialAsset
} from '../videos/components/FormInputs';
import { GET_TAGS, GET_TOPICS } from '../videos/graphql/Queries';
import { CREATE_PODCAST, UPDATE_PODCAST } from './graphql/Mutations';
import { GET_PODCAST } from './graphql/Queries';

const initialValues = {
  title: '',
  description: '',
  slug: '/',
  code: null,
  tags: [],
  topicIds: null,
  status: STATUS_TYPES.DRAFT,
  contentRating: '',
  authorIds: [],
  image: {
    ...initialAsset
  },
  imageThumbnail: {
    ...initialAsset
  },
  audioId: {
    ...initialAsset
  },
  metaHeader: '',
  metaFooter: '',
  permissions: []
};
const AddEditPodcast = ({ history, match: { params } }) => {
  const [form] = Form.useForm();
  const { podcastId } = params;
  const isEdit = !!podcastId;

  const {
    page,
    TYPES,
    setPage,
    getModalProps,
    openModal,
    navigateToEditPage
  } = useCreatePage(PAGE_TYPES.PODCAST);

  const [fetchPodcastDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_PODCAST,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (isEdit && !!podcastId) {
      fetchPodcastDetails({
        variables: {
          where: {
            id: podcastId
          }
        }
      }).then((res) => {
        const podcast = res?.data?.podcastAdmin;
        if (podcast) {
          const slug = podcast?.slug?.startsWith('/')
            ? podcast?.slug
            : `/${podcast?.slug}`;
          form.setFieldsValue({
            title: podcast?.title ?? '',
            description: podcast?.description ?? '',
            slug,
            permissions:
              podcast?.permissions?.map((value) => ({
                label: value,
                value
              })) ?? [],
            tags:
              podcast?.tags?.map((tag) => ({
                label: tag?.name,
                value: tag?.key
              })) ?? [],
            topicIds: podcast?.topics?.length
              ? {
                  label: podcast?.topics?.[0]?.name,
                  value: podcast?.topics?.[0]?.id
                }
              : null,
            status: podcast?.status ?? STATUS_TYPES.DRAFT,
            contentRating: podcast?.contentRating ?? '',
            authorIds:
              podcast?.authors?.map((author) => ({
                id: author.id ?? '',
                firstName: author.firstName ?? '',
                lastName: author.lastName ?? '',
                imageURL: author.image?.url ?? '',
                color: author.primaryColor ?? ''
              })) ?? [],
            image: {
              id: podcast?.image?.id ?? '',
              url: podcast?.image?.url ?? ''
            },
            imageThumbnail: {
              id: podcast?.imageThumbnail?.id ?? '',
              url: podcast?.imageThumbnail?.url ?? ''
            },
            audioId: podcast?.audio
              ? {
                  id: podcast?.audio?.id,
                  url: podcast?.audio?.serviceImageThumbnail,
                  title: podcast?.audio?.title
                }
              : {},
            metaHeader: podcast?.metaHeader ?? '',
            metaFooter: podcast?.metaFooter ?? '',
            code: podcast?.code ?? null
          });
          setPage({ ...podcast.page, slug: podcast.slug });
        }
      });
    }
  }, [isEdit, podcastId, form, fetchPodcastDetails, setPage]);

  const [addUpdatePodcast, { loading }] = useMutation(
    isEdit ? UPDATE_PODCAST : CREATE_PODCAST
  );

  const handleSubmit = (data) => {
    const payload = {
      title: data?.title || '',
      description: data?.description || '',
      slug: data?.slug?.startsWith('/') ? data?.slug?.substring(1) : data?.slug,
      code: data?.code || null,
      authorIds: data?.authorIds?.map((author) => author?.id) ?? [],
      tags: data?.tags?.map(({ value }) => value) || null,
      topicIds: data?.topicIds?.value ? [data?.topicIds?.value] : [],
      status: data?.status,
      contentRating: data?.contentRating || '',
      imageId: data?.image?.id || null,
      imageThumbnailId: data?.imageThumbnail?.id || null,
      audioId: data?.audioId?.id,
      metaHeader: data?.metaHeader || '',
      metaFooter: data?.metaFooter || '',
      permissions: data?.permissions?.map(({ value }) => value)
    };

    addUpdatePodcast({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: podcastId
          }
        })
      }
    }).then(() => {
      history.push(ROUTES.PODCAST_MODULE);
    });
  };

  const handleCancel = () => {
    history.replace(ROUTES.PODCAST_MODULE);
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  return (
    <div>
      <CreatePageModal {...getModalProps({ title: 'Create Podcast Page' })} />
      <PageHeader menu={MODULES.PODCAST} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={fetchingDetails}
          >
            <Form.Item
              label="Title"
              name="title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input placeholder="Enter title" onChange={handleTitleChange} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please enter slug!'
                },
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <SlugInput />
            </Form.Item>
            <Form.Item
              label="Unique Code"
              name="code"
              required
              rules={[
                {
                  required: true,
                  message: 'Please enter unique code!'
                },
                formValidatorRules?.maxLength(MAX_LENGTHS.KEY)
              ]}
            >
              <Input placeholder="Enter unique code" />
            </Form.Item>
            <Form.Item
              label="Speakers"
              name="authorIds"
              required
              rules={[
                {
                  async validator(_, value) {
                    if (value?.length < 1) {
                      throw new Error('Please select at least one speaker!');
                    }
                  }
                }
              ]}
            >
              <SelectAuthor multiple />
            </Form.Item>
            <Form.Item name="tags" label="Tags">
              <Select
                mode="multiple"
                placeholder="Select tags"
                query={GET_TAGS}
                variablesSelector={(filter) => ({ filter })}
                dataSelector={(data) =>
                  data?.tagsAdmin?.tags?.map(({ key, name }) => ({
                    label: name,
                    value: key
                  })) ?? []
                }
                keys={{
                  data: 'tagsAdmin',
                  records: 'tags',
                  count: 'count'
                }}
              />
            </Form.Item>
            <Form.Item
              name="topicIds"
              label="Topic"
              required
              rules={[
                {
                  async validator(_, value) {
                    if (!value) {
                      throw new Error('Please select topic!');
                    }
                  }
                }
              ]}
            >
              <Select
                placeholder="Select topic"
                query={GET_TOPICS}
                variablesSelector={(filter) => ({ filter })}
                dataSelector={(data) =>
                  data?.topicsAdmin?.topics?.map(({ id, name }) => ({
                    label: name,
                    value: id
                  })) ?? 0
                }
                keys={{
                  data: 'topicsAdmin',
                  records: 'topics',
                  count: 'count'
                }}
              />
            </Form.Item>
            <Form.Item label="Status" name="status">
              <AntdSelect
                options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                  ({ name, value }) => ({
                    label: name,
                    value
                  })
                )}
                placeholder="Select status"
              />
            </Form.Item>
            <Form.Item
              name="contentRating"
              label="Content Rating"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea placeholder="Enter content rating" />
            </Form.Item>
            <Form.Item label="Audio" name="audioId">
              <SelectAsset
                modalTitle="Select Audio"
                btnText="Audio"
                categoryKey={ASSET_CATEGORY.AUDIO}
                dataSelector={({ id, serviceImageThumbnail, title }) => ({
                  id,
                  title,
                  url: serviceImageThumbnail
                })}
              />
            </Form.Item>
            <Form.Item
              label="Image"
              name="image"
              extra="Recommended size  (2500 * 1242)"
            >
              <SelectAsset
                modalTitle="Select Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>
            <Form.Item
              label="Image Thumbnail"
              name="imageThumbnail"
              extra="Recommended size  (590 * 330)"
            >
              <SelectAsset
                modalTitle="Select Image Thumbnail"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image Thumbnail"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>
            <Form.Item
              name="metaHeader"
              label="Meta Header"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item
              name="metaFooter"
              label="Meta Footer"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>

            <Permissions />
            <div className="d-flex button-section mb-8">
              <Space>
                <Button
                  disabled={loading || fetchingDetails}
                  loading={loading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
            {isEdit && page.type === TYPES.CREATE && (
              <Button
                disabled={fetchingDetails}
                htmlType="button"
                type="text"
                className="text-btn"
                onClick={openModal}
              >
                Create Custom Page
              </Button>
            )}
            {isEdit && page.type === TYPES.UPDATE && (
              <Button
                disabled={fetchingDetails}
                htmlType="button"
                type="text"
                className="text-btn"
                onClick={navigateToEditPage}
              >
                Update Custom Page
              </Button>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEditPodcast;
