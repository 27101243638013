import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddEditPodcast from './AddEditPodcast';
import Podcast from './Podcast';

const PodcastModuleWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.PODCAST_MODULE} component={Podcast} />
      <Route
        exact
        path={`${ROUTES?.PODCAST_MODULE}/add`}
        component={AddEditPodcast}
      />
      <Route
        exact
        path={`${ROUTES?.PODCAST_MODULE}/:podcastId/edit`}
        component={AddEditPodcast}
      />
      <Route
        exact
        path={`${ROUTES?.PODCAST_MODULE}/:podcastId`}
        render={() => <Redirect to={ROUTES?.PODCAST_MODULE} />}
      />
    </Switch>
  );
};

export default PodcastModuleWrapper;
