import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { MAX_LENGTHS, MODULES, ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import { Select } from '../videos/components/FormInputs';
import {
  CREATE_VIDEO_PLATFORM,
  UPDATE_VIDEO_PLATFORM
} from './graphql/Mutations';
import { GET_VIDEO_PLATFORM, GET_VIDEO_PROVIDERS } from './graphql/Queries';

const initialValues = {
  name: '',
  provider: null,
  configs: {}
};

const videoProviderVariableSelctor = ({ skip, limit }) => ({
  filter: {
    skip,
    limit
  }
});

const getConfigValues = (configs = []) => {
  const configDefaultValue = {};
  configs?.forEach((config) => {
    configDefaultValue[config.key] = config?.defaultValue ?? '';
  });
  return configDefaultValue;
};

const AddEditVideoPlatform = ({ history, match: { params } }) => {
  const [form] = Form.useForm();
  const { platformId } = params;
  const isEdit = !!platformId;

  const [configFields, setConfigFields] = useState([]);

  const [getVideoPlatform, { loading: fetchingDetails }] = useLazyQuery(
    GET_VIDEO_PLATFORM,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateVideoPlatform, { loading }] = useMutation(
    isEdit ? UPDATE_VIDEO_PLATFORM : CREATE_VIDEO_PLATFORM
  );

  useEffect(() => {
    if (isEdit && !!platformId) {
      getVideoPlatform({
        variables: {
          where: {
            id: platformId
          }
        }
      }).then(({ data, error }) => {
        if (!error && !!data) {
          form.setFieldsValue({
            name: data?.videoPlatform?.name ?? '',
            provider: data?.videoPlatform?.provider
              ? {
                  label: data?.videoPlatform?.provider?.name,
                  value: data?.videoPlatform?.provider?.key
                }
              : null,
            configs: data?.videoPlatform?.configs
          });
          setConfigFields(data?.videoPlatform?.provider?.configs);
        }
      });
    }
  }, [isEdit, platformId, form]);

  const handleSubmit = ({ name, provider, configs }) => {
    const payload = {
      name,
      configs,
      ...(!isEdit && {
        providerKey: provider?.value
      })
    };

    addUpdateVideoPlatform({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: platformId
          }
        })
      }
    }).then(() => {
      history.push(ROUTES?.VIDEO_PLATFORMS);
    });
  };

  const handleCancel = () => {
    history.push(ROUTES?.VIDEO_PLATFORMS);
  };

  const handleProviderChange = (_, { configs }) => {
    setConfigFields(configs);
    const configDefaultValue = getConfigValues(configs);
    form.setFieldValue('configs', configDefaultValue);
  };

  return (
    <>
      <PageHeader menu={MODULES?.USERS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={fetchingDetails}
          >
            <Form.Item
              label="Platform Name"
              name="name"
              required
              rules={[
                formValidatorRules?.required('Please enter platform name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input placeholder="Enter platform name" />
            </Form.Item>
            <Form.Item
              label="Provider"
              name="provider"
              rules={[{ required: true, message: 'Please select provider!' }]}
            >
              <Select
                searchable={false}
                disabled={isEdit}
                readOnly={isEdit}
                dropdownMatchSelectWidth={false}
                variablesSelector={videoProviderVariableSelctor}
                onChange={handleProviderChange}
                placeholder="Select provider"
                query={GET_VIDEO_PROVIDERS}
                dataSelector={(data) =>
                  data?.videoProvidersAdmin?.videoProviders?.map(
                    ({ key, name, configs }) => ({
                      label: name,
                      value: key,
                      configs
                    })
                  ) ?? []
                }
                keys={{
                  data: 'videoProvidersAdmin',
                  records: 'videoProviders',
                  count: 'count'
                }}
              />
            </Form.Item>
            {configFields?.map(({ key, label, type, isRequired }) => {
              return (
                <Form.Item
                  key={key}
                  label={label}
                  name={['configs', key]}
                  {...(isRequired && {
                    required: true,
                    rules: [
                      formValidatorRules?.required(
                        `Please enter ${label.toLowerCase()}!`
                      )
                    ]
                  })}
                >
                  {
                    {
                      string: <Input />,
                      textarea: <Input.TextArea rows={4} />
                    }[type]
                  }
                </Form.Item>
              );
            })}
            <div className="d-flex button-section mb-8">
              <Space>
                <Button
                  disabled={loading || fetchingDetails}
                  loading={loading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditVideoPlatform;
