import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Space, theme } from 'antd';
import React, { useState } from 'react';
import audioPlaceholder from '../../../../assets/images/audioPlaceholder.jpg';
import videoPlaceholder from '../../../../assets/images/imagePlaceholder.png';
import { COLLECTIONS_COLORS } from '../../../../common/constants';
import { getImageUrl } from '../../../../common/utils';
import Image from '../../../../components/Image';
import SelectableModal from '../../../../components/SelectableModal';
import { GET_PODCASTS_MODULE } from '../../../podcast/graphql/Queries';
import Preview from '../../../videos/components/Preview';
import { GET_VIDEOS_MODULES } from '../../../videos/graphql/Queries';

export const ColorPicker = ({ onChange, value }) => {
  return (
    <div className="d-flex">
      {COLLECTIONS_COLORS?.map((color) => (
        <div
          key={color}
          className={`color-list ${color} ${
            value === color ? 'active-color' : ''
          }`}
          onClick={() => onChange(color)}
        />
      ))}
    </div>
  );
};

const VideoCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageThumbnail?.url, {
                height: 200,
                width: 200
              }) ?? videoPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

export const SelectVideos = ({
  multiple = true,
  onChange,
  value,
  id: fieldId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items.id === item.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Videos' : 'Select Video'}
        open={isOpen}
        onClose={closeModal}
        query={GET_VIDEOS_MODULES}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit }
        })}
        dataSelector={(data) => data?.videosAdmin?.videos ?? []}
        keys={{
          data: 'videosAdmin',
          records: 'videos',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <VideoCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || videoPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || videoPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button id={fieldId} htmlType="button" onClick={openModal}>
          {value.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Videos' : 'Video'}
        </Button>
      </Space>
    </>
  );
};

const PodcastCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageThumbnail?.url, {
                height: 200,
                width: 200
              }) ?? audioPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

export const SelectPodcasts = ({
  multiple = true,
  onChange,
  value,
  id: fieldId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items.id === item.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Podcasts' : 'Select Podcast'}
        open={isOpen}
        onClose={closeModal}
        query={GET_PODCASTS_MODULE}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit }
        })}
        dataSelector={(data) => data?.podcastsAdmin?.podcasts ?? []}
        keys={{
          data: 'podcastsAdmin',
          records: 'podcasts',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <PodcastCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || audioPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || audioPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button id={fieldId} htmlType="button" onClick={openModal}>
          {value?.id ? 'Change' : 'Select'} {multiple ? 'Podcasts' : 'Podcast'}
        </Button>
      </Space>
    </>
  );
};
