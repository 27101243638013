import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload, message } from 'antd';
import { isArray, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  ASSET_CATEGORY,
  AUDIO_SERVICE_TYPES,
  MAX_LENGTHS,
  MODULES,
  REGEX,
  ROUTES,
  allowedAudio,
  allowedImages
} from '../../../common/constants';
import {
  Blurhash,
  fileUpload,
  formValidatorRules,
  getBase64,
  uploadToPeerTube
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import PageHeader from '../../../components/PageHeader';
import PreviewModal from '../../../components/PreviewModal';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import {
  CREATE_ASSET,
  CREATE_AUDIO_ASSET,
  UPDATE_AUDIO_ASSET,
  UPDATE_AUDIO_ASSET_STATUS
} from '../graphql/Mutations';
import { GET_ASSET, GET_UPLOAD_SIGNED_URL } from '../graphql/Queries';

const { TextArea } = Input;

const AddEditAudio = (props) => {
  const { location } = props;
  const { audioId, isUpdate } = location?.state;
  const [form] = Form?.useForm();
  const [audioData, setAudioData] = useState({});
  const [audioLoading, setAudioLoading] = useState(true);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewAudio, setPreviewAudio] = useState();
  const [previewTitle, setPreviewTitle] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [audioProgress, setAudioProgress] = useState(undefined);
  const audioValue = Form?.useWatch('audio', form);
  const audioUrl = Form?.useWatch('audioUrl', form);
  const peerTubeAudioValue = Form?.useWatch('peerTubeAudio', form);
  const [fetchAudio] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: audioId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setAudioLoading(true);
      setAudioData(res?.asset);
      setAudioLoading(false);
    },
    onError: () => {
      setAudioLoading(false);
    }
  });

  useEffect(() => {
    if (audioId) {
      fetchAudio();
    } else {
      setAudioLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioId]);

  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL);
  const [createAssetMutate] = useMutation(CREATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [createAudioAsset] = useMutation(CREATE_AUDIO_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAudioAssetMutate] = useMutation(UPDATE_AUDIO_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });

  const [updateAudioAssetStatus] = useMutation(UPDATE_AUDIO_ASSET_STATUS);

  const handleCancel = () => {
    history?.replace(ROUTES?.AUDIO);
  };

  const handlePreview = async (file) => {
    let preview;
    if (!file?.url && !file?.preview) {
      preview = await getBase64(file?.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewTitle(
      file?.name ||
        file?.label ||
        file?.url?.substring(file?.url?.lastIndexOf('/') + 1)
    );
    setPreviewAudio(file?.url || preview);
  };

  const createImageAsset = async (values) => {
    const { thumbImage, title, description } = values;
    try {
      const textData = {
        title,
        description
      };
      let key = null;
      let blurhash = null;
      let contentType = null;

      if (thumbImage?.length > 0) {
        const imageFile = thumbImage?.[0]?.originFileObj;
        const fileName = thumbImage?.[0]?.name;
        contentType = thumbImage?.[0]?.type;
        const getSignedPutUrlResult = await getSignedUrl({
          variables: {
            data: {
              fileName: fileName?.replace(/ /g, '_'),
              contentType,
              assetType: ASSET_CATEGORY?.IMAGE
            }
          }
        });
        if (getSignedPutUrlResult?.data?.getAssetUploadSignedUrl) {
          await fileUpload(
            getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.signedUrl,
            imageFile
          );
          key = getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key;
          blurhash = await Blurhash?.encode(imageFile);
        }
      }
      const media = {
        ...textData,
        categoryKey: ASSET_CATEGORY?.IMAGE,
        contentType,
        key,
        blurhash
      };
      if (!key) {
        delete media?.key;
      }
      if (!blurhash) {
        delete media?.blurhash;
      }
      const response = await createAssetMutate({
        variables: {
          data: {
            ...media
          }
        }
      });
      if (response?.data?.createAsset) {
        return response;
      }
    } catch (error) {
      setButtonLoading(false);
      return error;
    }
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    const { audio, thumbImage, peerTubeAudio, title, description } = values;
    const textData = {
      title,
      description
    };
    let audioFile;

    if (isUpdate) {
      updateAudioAssetMutate({
        variables: {
          where: { id: audioId },
          data: textData
        }
      })
        .then(() => {
          history?.replace(ROUTES?.AUDIO);
          setButtonLoading(false);
        })
        .catch(() => {
          setAudioProgress(undefined);
          setButtonLoading(false);
        });
    } else {
      const importUrl = values?.audioUrl;
      let imageUploadRes;
      audioFile =
        peerTubeAudio?.[0]?.originFileObj || audio?.[0]?.originFileObj;
      // peertube with thumbnails
      if (thumbImage?.length > 0) {
        imageUploadRes = await createImageAsset(values);
      }
      const response = await createAudioAsset({
        variables: {
          data: {
            ...textData,
            service:
              peerTubeAudio?.length > 0
                ? AUDIO_SERVICE_TYPES?.PEERTUBE
                : AUDIO_SERVICE_TYPES?.MANUAL,
            ...(imageUploadRes && {
              imageAssetId: imageUploadRes?.data?.createAsset?.asset?.id
            }),
            ...(importUrl
              ? {
                  importUrl
                }
              : {
                  contentLength: audioFile?.size,
                  fileName: `${audioFile?.name?.replace(/\s/g, '_')}`
                })
          }
        }
      });
      if (importUrl) {
        setAudioProgress(undefined);
        setButtonLoading(false);
        history?.replace(ROUTES?.AUDIO);
        return;
      }
      setAudioProgress(0);
      if (response?.data?.createAudioAsset?.signedUrl) {
        let res;
        // peertube scenario
        if (peerTubeAudio?.length > 0) {
          res = await uploadToPeerTube(
            response?.data?.createAudioAsset?.signedUrl,
            audioFile,
            setAudioProgress
          );
          if (res && response?.data?.createAudioAsset?.asset?.id) {
            const data = JSON?.parse(res);
            if (peerTubeAudio?.length > 0) {
              updateAudioAssetStatus({
                variables: {
                  peertubeUUID: data.video?.uuid,
                  id: response?.data?.createAudioAsset?.asset?.id
                }
              })
                .then(() => {
                  history?.replace(ROUTES?.AUDIO);
                  setAudioProgress(undefined);
                  setButtonLoading(false);
                })
                .catch(() => {
                  setAudioProgress(undefined);
                  setButtonLoading(false);
                });
            }
          }
          // normal audio upload scenario
        } else {
          await fileUpload(
            response?.data?.createAudioAsset?.signedUrl,
            audioFile,
            setAudioProgress
          );
          history?.replace(ROUTES?.AUDIO);
          setAudioProgress(undefined);
          setButtonLoading(false);
        }
      }
    }
  };

  const initialValues = {
    ...audioData
  };

  if (audioLoading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <PreviewModal
        previewData={previewAudio}
        previewTitle={previewTitle}
        previewVisible={previewVisible}
        setPreviewVisible={setPreviewVisible}
        previewType={ASSET_CATEGORY?.AUDIO}
      />
      <PageHeader menu={MODULES?.ASSETS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            initialValues={initialValues || { isActive: true }}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input placeholder="Enter title" disabled={buttonLoading} />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <TextArea
                rows={2}
                placeholder="Enter description"
                disabled={buttonLoading}
              />
            </Form.Item>
            {!isUpdate && (
              <>
                <Form.Item
                  name="audio"
                  label="Audio"
                  normalize={(value) => {
                    const name = value?.[0]?.originFileObj?.name;
                    const ext = name?.substring(name?.lastIndexOf('.') + 1);
                    if (allowedAudio?.includes(ext)) {
                      return value;
                    }
                    return [];
                  }}
                  rules={[
                    {
                      async validator(_, value) {
                        if (
                          !value?.length &&
                          !audioUrl &&
                          !peerTubeAudioValue?.length
                        ) {
                          throw new Error('Please select audio');
                        }
                      }
                    }
                  ]}
                  getValueFromEvent={(e) => {
                    if (isArray(e)) {
                      return e;
                    }
                    return e?.fileList;
                  }}
                  valuePropName="fileList"
                >
                  <Upload
                    maxCount={1}
                    disabled={buttonLoading}
                    onPreview={handlePreview}
                    accept={map(allowedAudio, (item) => `.${item}`)?.join(', ')}
                    beforeUpload={() => false}
                    onChange={() =>
                      form?.resetFields([
                        'audioUrl',
                        'peerTubeAudio',
                        'thumbImage'
                      ])
                    }
                  >
                    <Space>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                      <span>
                        ({map(allowedAudio, (item) => `.${item}`)?.join(', ')})
                      </span>
                    </Space>
                  </Upload>
                </Form.Item>
                <div className="input-divider">Or</div>
                <Form.Item
                  rules={[
                    {
                      async validator(_, value) {
                        if (
                          !value?.length &&
                          !audioValue?.length &&
                          !peerTubeAudioValue?.length
                        ) {
                          throw new Error('Please enter url');
                        }
                        if (value && !REGEX?.WEB_URL?.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          throw new Error('Should be a valid URL');
                        }
                      }
                    }
                  ]}
                  name="audioUrl"
                  label="Import Audio"
                >
                  <Input
                    onChange={() =>
                      form?.resetFields([
                        'audio',
                        'peerTubeAudio',
                        'thumbImage'
                      ])
                    }
                    placeholder="Enter url"
                    disabled={buttonLoading}
                  />
                </Form.Item>
                <div className="input-divider">Or</div>
                <div className="text-label">Upload to Peertube</div>
                <div className="peertube-section">
                  <Form.Item
                    name="peerTubeAudio"
                    label="Audio"
                    rules={[
                      {
                        async validator(_, value) {
                          if (
                            !value?.length &&
                            !audioUrl &&
                            !audioValue?.length
                          ) {
                            throw new Error('Please select audio');
                          }
                        }
                      }
                    ]}
                    normalize={(value) => {
                      const name = value?.[0]?.originFileObj?.name;
                      const ext = name?.substring(name?.lastIndexOf('.') + 1);
                      if (allowedAudio?.includes(ext)) {
                        return value;
                      }
                      return [];
                    }}
                    getValueFromEvent={(e) => {
                      if (isArray(e)) {
                        return e;
                      }
                      return e?.fileList;
                    }}
                    valuePropName="fileList"
                  >
                    <Upload
                      maxCount={1}
                      disabled={buttonLoading}
                      onPreview={handlePreview}
                      accept={map(allowedAudio, (item) => `.${item}`)?.join(
                        ', '
                      )}
                      beforeUpload={() => false}
                      onChange={() => form?.resetFields(['audio', 'audioUrl'])}
                    >
                      <Space>
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                        <span>
                          ({map(allowedAudio, (item) => `.${item}`)?.join(', ')}
                          )
                        </span>
                      </Space>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    name="thumbImage"
                    label="Thumbnail Image"
                    normalize={(value) => {
                      const { name, size } = value?.[0]?.originFileObj || {};
                      const ext = name?.substring(name?.lastIndexOf('.') + 1);
                      const isLt4Mb = size / 1024 / 1024 < 4;
                      if (allowedImages?.includes(ext) && isLt4Mb) {
                        return value;
                      }
                      if (!isLt4Mb && name && size) {
                        message?.error('image size should be less than 4 mb!');
                      }
                      return [];
                    }}
                    getValueFromEvent={(e) => {
                      if (isArray(e)) {
                        return e;
                      }
                      return e?.fileList;
                    }}
                    valuePropName="fileList"
                  >
                    <Upload
                      maxCount={1}
                      disabled={buttonLoading}
                      onPreview={handlePreview}
                      accept={map(allowedImages, (item) => `.${item}`)?.join(
                        ', '
                      )}
                      beforeUpload={() => false}
                      onChange={() => form?.resetFields(['audio', 'audioUrl'])}
                    >
                      <Space>
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                        <span>
                          (
                          {map(allowedImages, (item) => `.${item}`)?.join(', ')}
                          &nbsp;&amp; &lt; 4MB )
                        </span>
                      </Space>
                    </Upload>
                  </Form.Item>
                </div>
              </>
            )}
            {audioProgress >= 0 && (
              <Form.Item>
                <ProgressBar progress={audioProgress} />
              </Form.Item>
            )}

            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={buttonLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={buttonLoading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditAudio;
