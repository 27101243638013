import Collectionimg from '../../assets/images/collection.png';
import FeaturedSpeakerImg from '../../assets/images/fs.png';

export const VideoCarouselData = [
  {
    id: '1',
    defaultValue: 40,
    time: '9:00',
    collection: 'part 1',
    category: 'category 1',
    title: 'Truth in Jest',
    date: '12/12/22',
    speaker: 'Tony Hale',
    tags: ['tag 1', 'tag 2', 'tag 3'],
    tagBackground: 'bg-n-200',
    description:
      'This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type.'
  },
  {
    id: '2',
    defaultValue: 10,
    time: '7:00',
    collection: 'part 2',
    category: 'category 2',
    title: 'The Meaning of Gender',
    date: '08/01/23',
    speaker: 'Dr. Preston Sprinkle, Dr. Paul Hruz & Kyla Gillespie',
    tags: ['tag 4', 'tag 5'],
    tagBackground: 'bg-yellow',
    description:
      'This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type.'
  },
  {
    id: '3',
    defaultValue: 80,
    time: '5:00',
    collection: 'part 3',
    category: 'category 3',
    title: 'Vocation is Integral',
    date: '09/01/23',
    speaker: 'Steven Garber',
    tags: ['tag 1', 'tag 2', 'tag 3'],
    tagBackground: 'bg-red-2',
    tagTextColor: 'text-n-0',
    description:
      'This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type.'
  },
  {
    id: '4',
    defaultValue: 75,
    time: '8:00',
    collection: 'part 4',
    category: 'category 4',
    title: 'Title of Video Goes Here',
    date: '25/01/23',
    speaker: 'Speaker Name',
    tags: ['tag 6'],
    tagBackground: 'bg-moss',
    description:
      'This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type.'
  },
  {
    id: '5',
    time: '10:00',
    collection: 'part 5',
    category: 'category 5',
    title: 'Truth in Jest',
    date: '25/12/22',
    speaker: 'Tony Hale'
  },
  {
    id: '6',
    defaultValue: 35,
    time: '4:00',
    collection: 'part 6',
    category: 'category 6',
    title: 'The Meaning of Gender',
    date: '20/12/22',
    speaker: 'Dr. Preston Sprinkle, Dr. Paul Hruz & Kyla Gillespie'
  },
  {
    id: '7',
    time: '3:00',
    collection: 'part 7',
    category: 'category 7',
    title: 'Vocation is Integral',
    date: '05/11/22',
    speaker: 'Steven Garber'
  },
  {
    id: '8',
    defaultValue: 40,
    collection: 'part 8',
    category: 'category 8',
    title: 'Title of Video Goes Here',
    date: '25/04/22',
    speaker: 'Speaker Name'
  }
];

export const PodcastCarouselData = [
  {
    id: '1',
    defaultValue: 40,
    time: '9:00',
    collection: 'part 1',
    category: 'category 1',
    title: 'Truth in Jest',
    date: '12/12/22',
    speaker: 'Tony Hale',
    tags: ['tag 1', 'tag 2', 'tag 3'],
    tagBackground: 'bg-n-200',
    description:
      'This is a collection of podcasts that are related. Maybe a series, course, or any other flexible content type.'
  },
  {
    id: '2',
    defaultValue: 10,
    time: '7:00',
    collection: 'part 2',
    category: 'category 2',
    title: 'The Meaning of Gender',
    date: '08/01/23',
    speaker: 'Dr. Preston Sprinkle, Dr. Paul Hruz & Kyla Gillespie',
    tags: ['tag 4', 'tag 5'],
    tagBackground: 'bg-yellow',
    description:
      'This is a collection of podcasts that are related. Maybe a series, course, or any other flexible content type.'
  },
  {
    id: '3',
    defaultValue: 80,
    time: '5:00',
    collection: 'part 3',
    category: 'category 3',
    title: 'Vocation is Integral',
    date: '09/01/23',
    speaker: 'Steven Garber',
    tags: ['tag 1', 'tag 2', 'tag 3'],
    tagBackground: 'bg-red-2',
    tagTextColor: 'text-n-0',
    description:
      'This is a collection of podcasts that are related. Maybe a series, course, or any other flexible content type.'
  },
  {
    id: '4',
    defaultValue: 75,
    time: '8:00',
    collection: 'part 4',
    category: 'category 4',
    title: 'Title of Podcast Goes Here',
    date: '25/01/23',
    speaker: 'Speaker Name',
    tags: ['tag 6'],
    tagBackground: 'bg-moss',
    description:
      'This is a collection of podcasts that are related. Maybe a series, course, or any other flexible content type.'
  },
  {
    id: '5',
    time: '10:00',
    collection: 'part 5',
    category: 'category 5',
    title: 'Truth in Jest',
    date: '25/12/22',
    speaker: 'Tony Hale'
  },
  {
    id: '6',
    defaultValue: 35,
    time: '4:00',
    collection: 'part 6',
    category: 'category 6',
    title: 'The Meaning of Gender',
    date: '20/12/22',
    speaker: 'Dr. Preston Sprinkle, Dr. Paul Hruz & Kyla Gillespie'
  },
  {
    id: '7',
    time: '3:00',
    collection: 'part 7',
    category: 'category 7',
    title: 'Vocation is Integral',
    date: '05/11/22',
    speaker: 'Steven Garber'
  },
  {
    id: '8',
    defaultValue: 40,
    collection: 'part 8',
    category: 'category 8',
    title: 'Title of Podcast Goes Here',
    date: '25/04/22',
    speaker: 'Speaker Name'
  }
];

export const FeaturedCollectionCarouselData = [
  {
    id: 1,
    img: Collectionimg,
    title: 'Women & Calling',
    videoCount: 12,
    description:
      'This course equips women to better understand and live out their unique, God-given gifts.',
    button: 'View Course'
  },
  {
    id: 2,
    img: Collectionimg,
    title: 'Women & Calling 1',
    videoCount: 8,
    description:
      'This course equips women to better understand and live out their unique, God-given gifts.',
    button: 'View Course'
  },
  {
    id: 3,
    img: Collectionimg,
    title: 'Women & Calling 2',
    videoCount: 10,
    description:
      'This course equips women to better understand and live out their unique, God-given gifts.',
    button: 'View Course'
  },
  {
    id: 4,
    img: Collectionimg,
    title: 'Women & Calling 3',
    videoCount: 7,
    description:
      'This course equips women to better understand and live out their unique, God-given gifts.',
    button: 'View Course'
  }
];

/* new data */
export const carouselData = [
  {
    id: '1',
    defaultValue: 40,
    time: '9:00',
    collection: 'part 1',
    category: 'category 1',
    title: 'Truth in Jest',
    date: '12/12/22',
    speaker: 'Tony Hale',
    tags: ['tag 1', 'tag 2', 'tag 3']
  },
  {
    id: '2',
    defaultValue: 10,
    time: '7:00',
    collection: 'part 2',
    category: 'category 2',
    title: 'The Meaning of Gender',
    date: '08/01/23',
    speaker: 'Dr. Preston Sprinkle, Dr. Paul Hruz & Kyla Gillespie'
  },
  {
    id: '3',
    defaultValue: 80,
    time: '5:00',
    collection: 'part 3',
    category: 'category 3',
    title: 'Vocation is Integral',
    date: '09/01/23',
    speaker: 'Steven Garber'
  },
  {
    id: '4',
    defaultValue: 75,
    time: '8:00',
    collection: 'part 4',
    category: 'category 4',
    title: 'Title of Video Goes Here',
    date: '25/01/23',
    speaker: 'Speaker Name'
  },
  {
    id: '5',
    time: '10:00',
    collection: 'part 5',
    category: 'category 5',
    title: 'Truth in Jest',
    date: '25/12/22',
    speaker: 'Tony Hale'
  },
  {
    id: '6',
    defaultValue: 35,
    time: '4:00',
    collection: 'part 6',
    category: 'category 6',
    title: 'The Meaning of Gender',
    date: '20/12/22',
    speaker: 'Dr. Preston Sprinkle, Dr. Paul Hruz & Kyla Gillespie'
  },
  {
    id: '7',
    time: '3:00',
    collection: 'part 7',
    category: 'category 7',
    title: 'Vocation is Integral',
    date: '05/11/22',
    speaker: 'Steven Garber'
  },
  {
    id: '8',
    defaultValue: 40,
    collection: 'part 8',
    category: 'category 8',
    title: 'Title of Video Goes Here',
    date: '25/04/22',
    speaker: 'Speaker Name'
  }
];
export const VideoListData = [
  {
    id: '1',
    defaultValue: 40,
    time: '9:00',
    collection: 'part 1',
    category: 'category 1',
    title: 'Truth in Jest',
    videoDescription: 'abc',
    date: '12/12/22',
    speaker: 'Tony Hale',
    tags: ['tag 1', 'tag 2', 'tag 3']
  },
  {
    id: '2',
    defaultValue: 10,
    time: '7:00',
    collection: 'part 2',
    category: 'category 2',
    title: 'The Meaning of Gender',
    date: '08/01/23',
    speaker: 'Dr. Preston Sprinkle, Dr. Paul Hruz & Kyla Gillespie'
  },
  {
    id: '3',
    defaultValue: 80,
    time: '5:00',
    collection: 'part 3',
    category: 'category 3',
    title: 'Vocation is Integral',
    date: '09/01/23',
    speaker: 'Steven Garber'
  },
  {
    id: '4',
    defaultValue: 75,
    time: '8:00',
    collection: 'part 4',
    category: 'category 4',
    title: 'Title of Video Goes Here',
    date: '25/01/23',
    speaker: 'Speaker Name'
  },
  {
    id: '5',
    time: '10:00',
    collection: 'part 5',
    category: 'category 5',
    title: 'Truth in Jest',
    date: '25/12/22',
    speaker: 'Tony Hale'
  },
  {
    id: '6',
    defaultValue: 35,
    time: '4:00',
    collection: 'part 6',
    category: 'category 6',
    title: 'The Meaning of Gender',
    date: '20/12/22',
    speaker: 'Dr. Preston Sprinkle, Dr. Paul Hruz & Kyla Gillespie'
  },
  {
    id: '7',
    time: '3:00',
    collection: 'part 7',
    category: 'category 7',
    title: 'Vocation is Integral',
    date: '05/11/22',
    speaker: 'Steven Garber'
  },
  {
    id: '8',
    defaultValue: 40,
    collection: 'part 8',
    category: 'category 8',
    title: 'Title of Video Goes Here',
    date: '25/04/22',
    speaker: 'Speaker Name'
  }
];
export const VideoGridData = [
  {
    id: '1',
    defaultValue: 40,
    time: '9:00',
    collection: 'part 1',
    category: 'category 1',
    title: 'Truth in Jest',
    date: '12/12/22',
    speaker: 'Tony Hale',
    tags: ['tag 1', 'tag 2', 'tag 3']
  },
  {
    id: '2',
    defaultValue: 10,
    time: '7:00',
    collection: 'part 2',
    category: 'category 2',
    title: 'The Meaning of Gender',
    date: '08/01/23',
    speaker: 'Dr. Preston Sprinkle, Dr. Paul Hruz & Kyla Gillespie'
  },
  {
    id: '3',
    defaultValue: 80,
    time: '5:00',
    collection: 'part 3',
    category: 'category 3',
    title: 'Vocation is Integral',
    date: '09/01/23',
    speaker: 'Steven Garber'
  },
  {
    id: '4',
    defaultValue: 75,
    time: '8:00',
    collection: 'part 4',
    category: 'category 4',
    title: 'Title of Video Goes Here',
    date: '25/01/23',
    speaker: 'Speaker Name'
  },
  {
    id: '5',
    time: '10:00',
    collection: 'part 5',
    category: 'category 5',
    title: 'Truth in Jest',
    date: '25/12/22',
    speaker: 'Tony Hale'
  },
  {
    id: '6',
    defaultValue: 35,
    time: '4:00',
    collection: 'part 6',
    category: 'category 6',
    title: 'The Meaning of Gender',
    date: '20/12/22',
    speaker: 'Dr. Preston Sprinkle, Dr. Paul Hruz & Kyla Gillespie'
  },
  {
    id: '7',
    time: '3:00',
    collection: 'part 7',
    category: 'category 7',
    title: 'Vocation is Integral',
    date: '05/11/22',
    speaker: 'Steven Garber'
  },
  {
    id: '8',
    defaultValue: 40,
    collection: 'part 8',
    category: 'category 8',
    title: 'Title of Video Goes Here',
    date: '25/04/22',
    speaker: 'Speaker Name'
  }
];
export const collectionSlider = [
  {
    id: 1,
    title: 'title 1',
    videoCount: 10
  },
  {
    id: 2,
    title: 'title 2',
    videoCount: 10
  },
  {
    id: 3,
    title: 'title 3',
    videoCount: 10
  },
  {
    id: 4,
    title: 'title 4',
    videoCount: 10
  },
  {
    id: 5,
    title: 'title 5',
    videoCount: 10
  },
  {
    id: 6,
    title: 'title 6',
    videoCount: 10
  },
  {
    id: 7,
    title: 'title 7',
    videoCount: 10
  },
  {
    id: 8,
    title: 'title 8',
    videoCount: 10
  }
];
export const collectionList = [
  {
    id: 1,
    videoCount: 10,
    title: 'title 1',
    description:
      'This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type.'
  },
  {
    id: 2,
    videoCount: 10,
    title: 'Title of Collection Goes Here',
    description:
      'This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type.'
  },
  {
    id: 3,
    videoCount: 10,
    title: 'Title of Collection Goes Here',
    description:
      'This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type.'
  },
  {
    id: 4,
    videoCount: 10,
    title: 'Title of Collection Goes Here',
    description:
      'This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type.'
  },
  {
    id: 5,
    videoCount: 10,
    title: 'Title of Collection Goes Here',
    description:
      'This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type.'
  },
  {
    id: 6,
    videoCount: 10,
    title: 'Title of Collection Goes Here',
    description:
      'This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type.'
  },
  {
    id: 7,
    videoCount: 10,
    title: 'Title of Collection Goes Here',
    description:
      'This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type.'
  },
  {
    id: 8,
    videoCount: 10,
    title: 'Title of Collection Goes Here',
    description:
      'This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type.'
  }
];

export const AuthorCarouselData = [
  {
    id: 1,
    speaker: 'Malcolm Gladwell',
    videoCount: '10'
  },
  {
    id: 2,
    speaker: 'Malcolm Gladwell',
    videoCount: '12'
  },
  {
    id: 3,
    speaker: 'Gabe Lyons',
    videoCount: '24'
  },
  {
    id: 4,
    speaker: 'Speaker Name',
    videoCount: '02'
  },
  {
    id: 5,
    speaker: 'Malcolm Gladwell',
    videoCount: '15'
  },
  {
    id: 6,
    speaker: 'Malcolm Gladwell',
    videoCount: '17'
  },
  {
    id: 7,
    speaker: 'Gabe Lyons',
    videoCount: '10'
  },
  {
    id: 8,
    speaker: 'Speaker Name',
    videoCount: '25'
  }
];
export const AuthorListData = [
  {
    id: 1,
    speaker: 'Malcolm Gladwell',
    videoCount: '10',
    description: `Lorem ipsum dolor sit amet consectetur. Vitae tristique donec libero
                   ullamcorper sit ultrices felis tristique.`
  },
  {
    id: 2,
    speaker: 'Malcolm Gladwell',
    videoCount: '12',
    description: `Lorem ipsum dolor sit amet consectetur. Vitae tristique donec libero
                   ullamcorper sit ultrices felis tristique.`
  },
  {
    id: 3,
    speaker: 'Gabe Lyons',
    videoCount: '24',
    description: `Lorem ipsum dolor sit amet consectetur. Vitae tristique donec libero
                   ullamcorper sit ultrices felis tristique.`
  },
  {
    id: 4,
    speaker: 'Speaker Name',
    videoCount: '02',
    description: `Lorem ipsum dolor sit amet consectetur. Vitae tristique donec libero
                   ullamcorper sit ultrices felis tristique.`
  },
  {
    id: 5,
    speaker: 'Malcolm Gladwell',
    videoCount: '15',
    description: `Lorem ipsum dolor sit amet consectetur. Vitae tristique donec libero
                   ullamcorper sit ultrices felis tristique.`
  },
  {
    id: 6,
    speaker: 'Malcolm Gladwell',
    videoCount: '17',
    description: `Lorem ipsum dolor sit amet consectetur. Vitae tristique donec libero
                   ullamcorper sit ultrices felis tristique.`
  },
  {
    id: 7,
    speaker: 'Gabe Lyons',
    videoCount: '10',
    description: `Lorem ipsum dolor sit amet consectetur. Vitae tristique donec libero
                   ullamcorper sit ultrices felis tristique.`
  },
  {
    id: 8,
    speaker: 'Speaker Name',
    videoCount: '25',
    description: `Lorem ipsum dolor sit amet consectetur. Vitae tristique donec libero
                   ullamcorper sit ultrices felis tristique.`
  }
];
export const CategorySliderData = [
  {
    id: 1,
    topic: 'Sexuality',
    href: '/',
    backgroundColor: 'bg-moss'
  },
  {
    id: 2,
    topic: 'Faith + Work',
    href: '/',
    backgroundColor: 'bg-blue'
  },
  {
    id: 3,
    topic: 'Gender',
    href: '/',
    backgroundColor: 'bg-red-2'
  },
  {
    id: 4,
    topic: 'Spiritual Practices',
    href: '/',
    backgroundColor: 'bg-yellow-2'
  },
  {
    id: 5,
    topic: 'Sexuality',
    href: '/',
    backgroundColor: 'bg-moss'
  },
  {
    id: 6,
    topic: 'Faith + Work',
    href: '/',
    backgroundColor: 'bg-blue'
  },
  {
    id: 7,
    topic: 'Gender',
    href: '/',
    backgroundColor: 'bg-red-2'
  },
  {
    id: 8,
    topic: 'Spiritual Practices',
    href: '/',
    backgroundColor: 'bg-yellow-2'
  }
];

export const TagCarouseldata = [
  {
    id: 1,
    name: 'Anxiety'
  },
  {
    id: 2,
    name: 'Voting'
  },
  {
    id: 3,
    name: 'Community'
  },
  {
    id: 4,
    name: 'Culture Summit 2023'
  },
  {
    id: 5,
    name: 'Spiritual Disciplines'
  },
  {
    id: 6,
    name: 'Parenting'
  },
  {
    id: 7,
    name: 'Church & State'
  },
  {
    id: 8,
    name: 'tag 8'
  },
  {
    id: 9,
    name: 'tag 9'
  },
  {
    id: 10,
    name: 'tag 10'
  }
];
export const FeaturedCarouselData = [
  {
    id: 1,
    tagName: 'Faith + Work',
    heading: 'Truth in Jest',
    subHeading: 'Tony Hale',
    date: '12/12/22',
    description:
      'Comedian and actor Tony Hale shares how comedy can tell deep truths in a way that’s both accessible and convicting.',
    tagData: [
      { id: 1, name: 'Comedy' },
      { id: 2, name: 'Interview' },
      { id: 3, name: 'Influence' }
    ]
  },
  {
    id: 2,
    tagName: 'LGBT',
    tagBackground: 'bg-moss',
    heading: 'The Meaning of Gender',
    subHeading: 'Dr. Preston Sprinkle, Dr. Paul Hruz & Kyla Gillespie',
    date: '12/12/22',
    description:
      'Comedian and actor Tony Hale shares how comedy can tell deep truths in a way that’s both accessible and convicting.',
    tagData: [
      { id: 1, name: 'Comedy' },
      { id: 2, name: 'Interview' },
      { id: 3, name: 'Influence' }
    ]
  },
  {
    id: 3,
    tagName: 'Faith + Work',
    heading: 'Truth in Jest',
    subHeading: 'Tony Hale',
    date: '12/12/22',
    description:
      'Comedian and actor Tony Hale shares how comedy can tell deep truths in a way that’s both accessible and convicting.',
    tagData: [
      { id: 1, name: 'Comedy' },
      { id: 2, name: 'Interview' },
      { id: 3, name: 'Influence' }
    ]
  },
  {
    id: 4,
    tagName: 'LGBT',
    tagBackground: 'bg-moss',
    heading: 'The Meaning of Gender',
    subHeading: 'Dr. Preston Sprinkle, Dr. Paul Hruz & Kyla Gillespie',
    date: '12/12/22',
    description:
      'Comedian and actor Tony Hale shares how comedy can tell deep truths in a way that’s both accessible and convicting.',
    tagData: [
      { id: 1, name: 'Comedy' },
      { id: 2, name: 'Interview' },
      { id: 3, name: 'Influence' }
    ]
  }
];

export const quotedata = [
  {
    id: 1,
    authImg: FeaturedSpeakerImg,
    quote:
      '“Why THINQ is just incredible and trustworthy in a sentence or two right here for everyone on the fence to read about and trust more!”',
    authName: 'Malcolm Gladwell',
    authSubtitle: 'Sub title of who they are'
  },
  {
    id: 2,
    authImg: FeaturedSpeakerImg,
    quote:
      '“Why THINQ is just incredible and trustworthy in a sentence or two right here for everyone on the fence to read about and trust more!”',
    authName: 'Malcolm Gladwell 2',
    authSubtitle: 'Sub title of who they are'
  },
  {
    id: 3,
    authImg: FeaturedSpeakerImg,
    quote:
      '“Why THINQ is just incredible and trustworthy in a sentence or two right here for everyone on the fence to read about and trust more!”',
    authName: 'Malcolm Gladwell 3',
    authSubtitle: 'Sub title of who they are'
  }
];
export const faqData = [
  {
    id: 1,
    heading: 'Accordion',
    cmsContent:
      'Here goes accordion text.</br> You can detach this instance if you want other components than text inside it.'
  },
  {
    id: 2,
    heading: 'Accordion 2',
    cmsContent:
      '<p class="mb-8"">Donate by Check:<br/>1422 Street Name <br/>City and State 23333</p> <p>Donate by Phone Call: </br>800-535-3663</p>'
  },
  {
    id: 3,
    heading: 'Accordion 3',
    cmsContent:
      'Here goes accordion text.</br> You can detach this instance if you want other components than text inside it.'
  },
  {
    id: 4,
    heading: 'Accordion 4',
    cmsContent:
      'Here goes accordion text.</br> You can detach this instance if you want other components than text inside it.'
  },
  {
    id: 5,
    heading: 'Accordion 5',
    cmsContent:
      'Here goes accordion text.</br> You can detach this instance if you want other components than text inside it.'
  },
  {
    id: 6,
    heading: 'Accordion 6',
    cmsContent:
      'Here goes accordion text.</br> You can detach this instance if you want other components than text inside it.'
  }
];

export const upcomingTableData = [
  {
    id: 1,
    cmsContent: '<i>‘This is a very very,<br/>really really long title.’</i>',
    time: 'Mondays, 9 AM',
    date: 'Dec 5-26',
    user: '1',
    map: 'Details provided upon RSVP'
  },
  {
    id: 2,
    cmsContent: '<i>‘Truth in Culture’</i>',
    time: 'Mondays, 10 AM',
    date: 'Dec 6-26',
    user: '2',
    map: 'Details provided upon RSVP'
  },
  {
    id: 3,
    cmsContent: '<i>‘This is a very very,<br/>really really long title.’</i>',
    time: 'Mondays, 11 AM',
    date: 'Dec 7-26',
    user: '3',
    map: 'Details provided upon RSVP'
  },
  {
    id: 4,
    cmsContent: '<i>‘This is a very very,<br/>really really long title.’</i>',
    time: 'Mondays, 12 AM',
    date: 'Dec 8-26',
    user: '4',
    map: 'Details provided upon RSVP'
  },
  {
    id: 5,
    cmsContent: '<i>‘This is a very very,<br/>really really long title.’</i>',
    time: 'Mondays, 1 AM',
    date: 'Dec 9-26',
    user: '5',
    map: 'Details provided upon RSVP'
  },
  {
    id: 6,
    cmsContent: '<i>‘This is a very very,<br/>really really long title.’</i>',
    time: 'Mondays, 6 AM',
    date: 'Dec 10-26',
    user: '7',
    map: 'Details provided upon RSVP'
  }
];

export const VideoListStartTable = [
  {
    id: '1',
    defaultValue: 40,
    time: '9:00',
    title: 'Work for the Common Good',
    videoDescription: 'Skye Jethani'
  },
  {
    id: '2',
    defaultValue: 40,
    time: '9:00',
    title: 'Work for the Common Good',
    videoDescription: 'Skye Jethani'
  },
  {
    id: '3',
    defaultValue: 40,
    time: '9:00',
    title: 'Work for the Common Good',
    videoDescription: 'Skye Jethani'
  },
  {
    id: '4',
    defaultValue: 40,
    time: '9:00',
    title: 'Work for the Common Good',
    videoDescription: 'Skye Jethani'
  },
  {
    id: '5',
    defaultValue: 40,
    time: '9:00',
    title: 'Work for the Common Good',
    videoDescription: 'Skye Jethani'
  },
  {
    id: '6',
    defaultValue: 40,
    time: '9:00',
    title: 'Work for the Common Good',
    videoDescription: 'Skye Jethani'
  },
  {
    id: '7',
    defaultValue: 40,
    time: '9:00',
    title: 'Work for the Common Good',
    videoDescription: 'Skye Jethani'
  },
  {
    id: '8',
    defaultValue: 40,
    time: '9:00',
    title: 'Work for the Common Good',
    videoDescription: 'Skye Jethani'
  }
];

export const videoselectedTotal = [
  {
    id: '1',
    defaultValue: 40,
    time: '9:00',
    title: 'Work for the Common Good',
    videoDescription: 'Skye Jethani'
  },
  {
    id: '2',
    defaultValue: 40,
    time: '9:00',
    title: 'Work for the Common Good',
    videoDescription: 'Skye Jethani'
  },
  {
    id: '3',
    defaultValue: 40,
    time: '9:00',
    title: 'Work for the Common Good',
    videoDescription: 'Skye Jethani'
  },
  {
    id: '4',
    defaultValue: 40,
    time: '9:00',
    title: 'Work for the Common Good',
    videoDescription: 'Skye Jethani'
  }
];

export const FilterTeg = [
  {
    id: 1,
    name: 'Season 1'
  },
  {
    id: 2,
    name: 'Season 2'
  },
  {
    id: 3,
    name: 'Season 3'
  },
  {
    id: 4,
    name: 'Season 4'
  }
];
export const FeaturedCollectionData = [
  {
    id: 1,
    img: '/images/fc.png',
    title: 'Women & Calling',
    videoCount: '12 Videos',
    description:
      'This course equips women to better understand and live out their unique, God-given gifts.',
    button: 'View Course'
  },
  {
    id: 2,
    img: '/images/fc.png',
    title: 'Women & Calling 1',
    videoCount: '12 Videos',
    description:
      'This course equips women to better understand and live out their unique, God-given gifts.',
    button: 'View Course'
  },
  {
    id: 3,
    img: '/images/fc.png',
    title: 'Women & Calling 2',
    videoCount: '12 Videos',
    description:
      'This course equips women to better understand and live out their unique, God-given gifts.',
    button: 'View Course'
  },
  {
    id: 4,
    img: '/images/fc.png',
    title: 'Women & Calling 3',
    videoCount: '12 Videos',
    description:
      'This course equips women to better understand and live out their unique, God-given gifts.',
    button: 'View Course'
  }
];

export const TagPageVideoGridData = [
  {
    id: '1',
    title: 'Truth in Jest'
  },
  {
    id: '2',
    title: 'The Meaning of Gender'
  },
  {
    id: '3',
    title: 'Vocation is Integral'
  },
  {
    id: '4',
    title: 'Title of Video Goes Here'
  },
  {
    id: '5',
    title: 'Truth in Jest'
  },
  {
    id: '6',
    title: 'The Meaning of Gender'
  },
  {
    id: '7',
    title: 'Vocation is Integral'
  }
];

export const CategoryCarouselData = [
  {
    id: '1',
    title: 'Why I Love My Lane (And How I Found It)',
    date: '12/12/22',
    speaker: 'Annie F. Downs'
  },
  {
    id: '2',
    title: 'Creating and Flourishing',
    date: '12/12/22',
    speaker: 'Dana Tanamachi'
  },
  {
    id: '3',
    title: 'Public Faith Unscripted',
    date: '12/12/22',
    speaker: 'Ernie Johnson'
  },
  {
    id: '4',
    title: 'The New Story of Work',
    date: '12/12/22',
    speaker: 'Matt Rusten'
  },
  {
    id: '5',
    title: 'Introduction | Faith + Work',
    date: '12/12/22',
    speaker: 'Missy Wallace'
  },
  {
    id: '6',
    title: 'Why I Love My Lane (And How I Found It)',
    date: '12/12/22',
    speaker: 'Annie F. Downs'
  },
  {
    id: '7',
    title: 'On Calling, Ambition and Surrender',
    date: '12/12/22',
    speaker: 'Pete Richardson'
  },
  {
    id: '8',
    title: 'Why I Love My Lane (And How I Found It)',
    date: '12/12/22',
    speaker: 'Annie F. Downs'
  }
];
