import { gql } from '@apollo/client';

export const CORE_TAG_FIELDS = gql`
  fragment CorseFields on Tag {
    key
    name
  }
`;

export const GET_TAGS = gql`
  ${CORE_TAG_FIELDS}
  query tagsAdmin($filter: TagsFilter, $sort: TagsSort) {
    tagsAdmin(filter: $filter, sort: $sort) {
      count
      tags {
        ...CorseFields
      }
    }
  }
`;

export const GET_TAG = gql`
  ${CORE_TAG_FIELDS}
  query tagAdmin($key: String!) {
    tagAdmin(where: { key: $key }) {
      ...CorseFields
      slug
      metaHeader
      metaFooter
      page {
        id
        isDefault
      }
    }
  }
`;
