import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import AddEditVideo from './AddEditVideo';
import ImportVideo from './ImportVideo';
import Videos from './Videos';

const VideosWrapper = () => (
  <Switch>
    <Route path={ROUTES?.VIDEOS} exact component={Videos} />
    <Route path={`${ROUTES?.VIDEOS}/import`} component={ImportVideo} />
    <Route path={`${ROUTES?.VIDEOS}/add`} component={AddEditVideo} />
    <Route path={`${ROUTES?.VIDEOS}/:id/edit`} component={AddEditVideo} />
    <Route
      exact
      path={`${ROUTES?.VIDEOS}/:id`}
      render={() => <Redirect to={ROUTES?.VIDEOS} />}
    />
  </Switch>
);

export default VideosWrapper;
