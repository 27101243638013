import { Collapse, Space, Spin, Typography } from 'antd';
import { groupBy, map } from 'lodash';
import { CaretDown, CaretUp } from 'phosphor-react';
import React, { useEffect } from 'react';
import {
  FORM_TYPES,
  getDefaultPlaceholderValues,
  useEditPage
} from '../../context/EditPageProvider';

const { Panel } = Collapse;

const AddModule = () => {
  const {
    pageModules,
    loadingPageModules: loading,
    fetchPageModules,
    addModule,
    setForm,
    setSelectionType,
    addIndex
  } = useEditPage();

  useEffect(() => {
    fetchPageModules();
  }, []);

  const handleClick = (key) => {
    const data = getDefaultPlaceholderValues(key);

    if (data) {
      setForm({ type: FORM_TYPES.ADD, index: addIndex });
      addModule(data);
      setSelectionType(data.type);
    }
  };

  return (
    <Space direction="vertical" size="large">
      <Typography.Text className="title">Add a module:</Typography.Text>
      {loading ? (
        <Spin />
      ) : (
        <Space className="module-list" direction="vertical">
          <Collapse
            className="module-collapse"
            ghost
            expandIcon={({ isActive }) =>
              isActive ? <CaretUp size={20} /> : <CaretDown size={20} />
            }
          >
            {map(groupBy(pageModules, 'category'), (item, key) => (
              <Panel
                header={
                  <Typography.Text
                    onClick={() => handleClick(item?.key)}
                    key={item?.key}
                  >
                    <div className="height-36">{key}</div>
                  </Typography.Text>
                }
                collapsible="header"
                key={key}
              >
                {map(item, ({ key: panelKey, name }) => (
                  <Typography.Text
                    onClick={() => handleClick(panelKey)}
                    key={panelKey}
                  >
                    <div className="height-36">{name}</div>
                  </Typography.Text>
                ))}
              </Panel>
            ))}
          </Collapse>
        </Space>
      )}
    </Space>
  );
};

export default AddModule;
