import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload } from 'antd';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  ROUTES
} from '../../../common/constants';
import { fileUpload, formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import PageHeader from '../../../components/PageHeader';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import { CREATE_ASSET, UPDATE_ASSET } from '../graphql/Mutations';
import { GET_ASSET, GET_UPLOAD_SIGNED_URL } from '../graphql/Queries';

const { TextArea } = Input;

const AddEditText = (props) => {
  const { location } = props;
  const { textId, isUpdate } = location?.state;
  const [textData, setTextData] = useState({});
  const [textLoading, setTextLoading] = useState(true);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [textProgress, setTextProgress] = useState(undefined);
  const [fetchText] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: textId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setTextLoading(true);
      setTextData(res?.asset);
      setTextLoading(false);
    },
    onError: () => {
      setTextLoading(false);
    }
  });

  useEffect(() => {
    if (textId) {
      fetchText();
    } else {
      setTextLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textId]);

  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL);
  const [createAssetMutate] = useMutation(CREATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAssetMutate] = useMutation(UPDATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    history?.replace(ROUTES?.TEXT);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    const { text } = values;
    try {
      const formData = {
        title: values?.title,
        description: values?.description
      };
      let key = null;
      let contentType = null;
      if (text?.length > 0) {
        setTextProgress(0);
        const textFile = text?.[0]?.originFileObj;
        const fileName = text?.[0]?.name;
        contentType = text?.[0]?.type;
        const getSignedPutUrlResult = await getSignedUrl({
          variables: {
            data: {
              fileName: fileName?.replace(/ /g, '_'),
              contentType,
              assetType: ASSET_CATEGORY?.TEXT
            }
          }
        });
        if (getSignedPutUrlResult?.data?.getAssetUploadSignedUrl) {
          await fileUpload(
            getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.signedUrl,
            textFile,
            setTextProgress
          );
          key = getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key;
        }
      }
      const media = {
        ...formData,
        categoryKey: ASSET_CATEGORY?.TEXT,
        contentType,
        key
      };
      if (!key) {
        delete media?.key;
      }
      if (isUpdate) {
        const response = await updateAssetMutate({
          variables: {
            where: { id: textId },
            data: {
              ...media
            }
          }
        });
        if (response?.data?.updateAsset) {
          setButtonLoading(false);
          history?.replace(ROUTES?.TEXT);
        }
      } else {
        const response = await createAssetMutate({
          variables: {
            data: {
              ...media
            }
          }
        });
        if (response?.data?.createAsset) {
          setTextProgress(undefined);
          setButtonLoading(false);
          history?.replace(ROUTES?.TEXT);
        }
      }
    } catch (error) {
      setTextProgress(undefined);
      setButtonLoading(false);
      return error;
    }
  };

  const initialValues = {
    ...textData
  };

  if (textLoading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <PageHeader menu={MODULES?.ASSETS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            initialValues={initialValues || { isActive: true }}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input placeholder="Enter title" disabled={buttonLoading} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <TextArea
                rows={2}
                placeholder="Enter description"
                disabled={buttonLoading}
              />
            </Form.Item>

            <Form.Item
              name="text"
              label="Text Track"
              rules={[{ required: !isUpdate, message: 'Please select text!' }]}
              getValueFromEvent={(e) => {
                if (isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
              valuePropName="fileList"
            >
              <Upload
                maxCount={1}
                disabled={buttonLoading}
                accept=".vtt"
                beforeUpload={() => false}
              >
                <Space>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  <span>(.vtt)</span>
                </Space>
              </Upload>
            </Form.Item>

            {textProgress >= 0 && (
              <Form.Item>
                <ProgressBar progress={textProgress} />
              </Form.Item>
            )}

            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={buttonLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={buttonLoading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditText;
