import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload } from 'antd';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  ROUTES
} from '../../../common/constants';
import {
  formValidatorRules,
  getBase64,
  uploadToPeerTube
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import PageHeader from '../../../components/PageHeader';
import PreviewModal from '../../../components/PreviewModal';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import { IMPORT_PLATFORM_VIDEO_TO_ASSET } from '../../video-platforms/graphql/Mutations';
import {
  Select,
  SelectProviderVideo
} from '../../videos/components/FormInputs';
import {
  CREATE_VIDEO_ASSET,
  UPDATE_VIDEO_ASSET,
  UPDATE_VIDEO_ASSET_STATUS
} from '../graphql/Mutations';
import { GET_ASSET, GET_LANGUAGES } from '../graphql/Queries';

const { TextArea } = Input;

const languageVariableSelctor = ({ skip, limit, search }) => ({
  filter: {
    skip,
    limit,
    search
  }
});

export const SelectLanguage = ({ ...rest }) => {
  return (
    <Select
      dropdownMatchSelectWidth={false}
      variablesSelector={languageVariableSelctor}
      placeholder="Select language"
      query={GET_LANGUAGES}
      dataSelector={(data) =>
        data?.languages?.languages?.map(({ code, name }) => ({
          label: name,
          value: code
        })) ?? []
      }
      keys={{
        data: 'languages',
        records: 'languages',
        count: 'count'
      }}
      queryOptions={{
        fetchPolicy: 'cache-first'
      }}
      {...rest}
    />
  );
};

const AddEditVideo = (props) => {
  const {
    match: { params }
  } = props;
  const { id: videoId } = params;
  const isUpdate = !!videoId;
  const [form] = Form.useForm();
  const videoUrl = Form.useWatch('videoUrl', form);
  const providerVideo = Form.useWatch('providerVideo', form);
  const [videoData, setVideoData] = useState({});
  const [videoLoading, setVideoLoading] = useState(true);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewVideo, setPreviewVideo] = useState();
  const [previewTitle, setPreviewTitle] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [videoProgress, setVideoProgress] = useState(undefined);
  const [fetchVideo] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: videoId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setVideoLoading(true);
      setVideoData(res?.asset);
      setVideoLoading(false);
    },
    onError: () => {
      setVideoLoading(false);
    }
  });

  useEffect(() => {
    if (videoId) {
      fetchVideo();
    } else {
      setVideoLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  const [createVideoAssetMutate] = useMutation(CREATE_VIDEO_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [createPlatformVideoAssetMutate] = useMutation(
    IMPORT_PLATFORM_VIDEO_TO_ASSET,
    {
      onError() {
        setButtonLoading(false);
      }
    }
  );
  const [updateVideoAssetMutate] = useMutation(UPDATE_VIDEO_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateVideoAssetStatus] = useMutation(UPDATE_VIDEO_ASSET_STATUS);

  const handlePreview = async (file) => {
    let preview;
    if (!file?.url && !file?.preview) {
      preview = await getBase64(file?.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewTitle(
      file?.name ||
        file?.label ||
        file?.url?.substring(file?.url?.lastIndexOf('/') + 1)
    );
    setPreviewVideo(file?.url || preview);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    const { video } = values;
    try {
      const textData = {
        title: values?.title,
        description: values?.description,
        languageCode: values?.languageCode?.value
      };
      const videofile = video?.[0]?.originFileObj;

      if (isUpdate) {
        updateVideoAssetMutate({
          variables: {
            where: { id: videoId },
            data: textData
          }
        })
          .then(() => {
            history?.replace(ROUTES?.VIDEOS);
            setButtonLoading(false);
          })
          .catch(() => {
            setVideoProgress(undefined);
            setButtonLoading(false);
          });
      } else {
        const platformId = values?.providerVideo?.platformId;
        if (platformId) {
          const response = await createPlatformVideoAssetMutate({
            variables: {
              where: {
                platformId,
                videoId: values?.providerVideo?.id
              },
              data: {
                ...textData
              }
            }
          });
          if (response?.data?.importPlatformVideoToAsset) {
            setVideoProgress(undefined);
            setButtonLoading(false);
            history?.replace(ROUTES?.VIDEOS);
            return;
          }
        }
        const importUrl = values?.videoUrl;
        const response = await createVideoAssetMutate({
          variables: {
            data: {
              ...textData,
              ...(importUrl
                ? { importUrl }
                : {
                    contentLength: videofile.size,
                    fileName: `${videofile.name.replace(/\s/g, '_')}`
                  })
            }
          }
        });
        if (importUrl) {
          setVideoProgress(undefined);
          setButtonLoading(false);
          history?.replace(ROUTES?.VIDEOS);
          return;
        }
        setVideoProgress(0);
        if (response?.data?.createVideoAsset?.signedUrl) {
          const res = await uploadToPeerTube(
            response?.data?.createVideoAsset?.signedUrl,
            videofile,
            setVideoProgress
          );
          if (res && response?.data?.createVideoAsset?.asset?.id) {
            const data = JSON.parse(res);

            updateVideoAssetStatus({
              variables: {
                peertubeUUID: data.video?.uuid,
                id: response?.data?.createVideoAsset?.asset?.id
              }
            })
              .then(() => {
                history?.replace(ROUTES?.VIDEOS);
                setVideoProgress(undefined);
                setButtonLoading(false);
              })
              .catch(() => {
                setVideoProgress(undefined);
                setButtonLoading(false);
              });
          }
        }
      }
    } catch (error) {
      setVideoProgress(undefined);
      setButtonLoading(false);
      return error;
    }
  };

  const handleCancel = () => {
    history?.replace(ROUTES?.VIDEOS);
  };

  const initialValues = {
    ...videoData,
    languageCode: {
      label: 'English',
      value: 'EN'
    },
    providerVideo: {
      id: '',
      title: '',
      description: '',
      imageURL: '',
      platformId: ''
    },
    videoUrl: ''
  };

  if (videoLoading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <PreviewModal
        previewData={previewVideo}
        previewTitle={previewTitle}
        previewVisible={previewVisible}
        setPreviewVisible={setPreviewVisible}
        previewType={ASSET_CATEGORY?.VIDEO}
      />
      <PageHeader menu={MODULES?.ASSETS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            initialValues={initialValues || { isActive: true }}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input placeholder="Enter title" disabled={buttonLoading} />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <TextArea
                rows={2}
                placeholder="Enter description"
                disabled={buttonLoading}
              />
            </Form.Item>
            <Form.Item name="languageCode" label="Language Code">
              <SelectLanguage />
            </Form.Item>

            {!isUpdate && (
              <>
                <Form.Item
                  name="video"
                  label="Upload Video"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (!value?.length && !videoUrl && !providerVideo?.id) {
                          throw new Error('Please select video');
                        }
                      }
                    }
                  ]}
                  getValueFromEvent={(e) => {
                    if (isArray(e)) {
                      return e;
                    }
                    return e?.fileList;
                  }}
                  valuePropName="fileList"
                >
                  <Upload
                    maxCount={1}
                    disabled={buttonLoading}
                    onPreview={handlePreview}
                    accept=".mp4"
                    beforeUpload={() => false}
                    onChange={() =>
                      form.resetFields(['providerVideo', 'videoUrl'])
                    }
                  >
                    <Space>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                      <span>(.mp4)</span>
                    </Space>
                  </Upload>
                </Form.Item>
                <div className="input-divider">Or</div>
                <Form.Item name="videoUrl" label="Import Video">
                  <Input
                    placeholder="Enter url"
                    disabled={buttonLoading}
                    onChange={() =>
                      form.resetFields(['providerVideo', 'video'])
                    }
                  />
                </Form.Item>
                <div className="input-divider">Or</div>
                <Form.Item
                  name="providerVideo"
                  label="Select Video From Platform"
                >
                  <SelectProviderVideo
                    onChange={({ title, description }) => {
                      form.setFieldValue('title', title);
                      form.setFieldValue('description', description);
                      form.resetFields(['videoUrl', 'video']);
                    }}
                  />
                </Form.Item>
              </>
            )}
            {videoProgress >= 0 && (
              <Form.Item>
                <ProgressBar progress={videoProgress} />
              </Form.Item>
            )}

            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={buttonLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={buttonLoading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditVideo;
